﻿.cardstyle {
    background-color: antiquewhite !important;
}

.cardbodystyle {
    padding: 0.2rem !important;
    color: #148 !important;
    vertical-align: middle;
}

.headertext {
    font-size: 18px !important;
    font-weight: 700;
    padding-top: 5px;
}

.cardbodytext {
    font-size: 14px;
    padding: 0.4rem !important;
    color: rgba(0,0,0,0.87) !important;
}

.leftrighticonsReachability {
    font-size: 22px;
    font-weight: bolder;
    color: white;
    vertical-align: middle;
}

.divpaddingtop {
    padding-top: 5px;
}

.Reachabilityheader {
    font-size: 18px !important;
    padding-left: 40px;
}

.buttondiv {
    text-align: right !important;
}

.Reachabilitybutton {
    border-radius: 4px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    color: white !important;
}

.Reachabilitybutton-Retain {
    border-radius: 4px;
    border-color: forestgreen !important;
    background-color: forestgreen !important;
    outline: none;
    color: white !important;
}

.inputelemReachability:focus {
    border: 2px solid black !important;
}

.tdwidth100{
    width: 100px;
}

th.thSort {
    cursor: pointer;
}

th.thSort > div > div {
    color: lightslategray;
    text-decoration: none;
    display: inline;
}

th.thSort > div > div:hover {
    color: darkgrey;
}

th.thSort > div > div.iconSort.active {
    color: #ffffff;
}

.ReachabilityErrortable {
    border-radius: 5px !important;
    width: 100%;
}

.ReachabilityErrortable thead tr th {
    color: #000000 !important;
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}

.ReachabilityErrortable tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.ReachabilityErrortable tbody tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}

.ReachabilityErrortable td {
    border: 1px solid #00000029 !important;
}

.RetainCheckbox {
    textAlign: center !important;
    width: 10%;
    vertical-align: bottom !important;
    font-size: 16px;
    color:red !important;
    background-color: yellow !important;
    font-weight: bold;
}