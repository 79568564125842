﻿.Orange {
    color: Orange !important;
}

.RedColor {
    color: pink !important;
    font-size: 20px !important;
}
.Groupnamepadding{
    padding-top:10px !important;
}
.switchpadding {
    padding-left: 5px !important;
}
//====================starting of styles for Saw machine (150% scale)==========================//
@media only screen and (max-width: 1280px) and (max-width: 1536px) {
    .navbarfontsize {
        font-size: 12px !important;
    }

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        font-size: small;
        margin-left: 0px;
        padding-left: 0px;
    }

    .headerfontsize {
        font-size: 14px !important;
        vertical-align: middle !important;
    }

    .Orange {
        color: Orange !important;
        font-size: 10px !important;
    }

    .RedColor {
        color: pink !important;
        font-size: 15px !important;
    }
    .switchpadding {
        padding-left: 0px !important;
    }
    .adminFormat {
        font-size: 14px !important;
        vertical-align: middle !important;
    }
}
//====================end of styles for Saw machine (150% scale)==========================//
