﻿.dropdown_email_config .p-dropdown-items-wrapper {
    min-width: 25rem;
}
.emailclearbtn {
    color: #ffffff !important;
    background-color: #337ab7 !important;
    border: 1px solid #2e6da4 !important;
    width: 80px !important;
    text-align:center;
}

.divAddEmailConfig {       
    width: 150px;
    padding: 3px;
}

.HeaderTable{
    width: 100%;

}

.HeaderTableTD1 {
    width: 30%;
}

.HeaderTableTD2 {
    width: 60%;
}

.HeaderTableTD3 {
    width: 10%;
}


.picklist-emailConfig .heatmap-item {
    display: flex;
    align-items: center;
    padding: .5rem;
}

.picklist-emailConfig {
    display: flex;
    align-items: center;
    padding: .5rem;
    width: 100%!important;
}

.card-emailConfig {
    background-color: white;
    box-shadow: 0 0.5em 1em -0.100em;
    color: #4a4a4a;
    width: 100%!important;
}

.p-picklist .p-picklist-buttons {
    height: 12.5em;
    padding: 0 .25em;
    width: 3%;
}

body .p-picklist .p-picklist-list {
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    padding: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    min-width:100%;
}

/*body .p-component {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    text-decoration: none;
    width: 100%;
}*/

.searchstyleEmailconfig > div:last-child > div {
    display: block;
}

.Emailbuttons {
    padding: 7px !important;
    border-radius: 4px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    color: white;
    min-width:100px;
}

.EmailConfigBoxShadow {
    color: #100;
    border: 0px solid;
    margin: 0 0 1rem 0;
    box-shadow: inset -1px -1px 17px 3px lightblue;
    padding: 3px !important;
    align-items: center;
    align-content: center;
    align-self: center;
    display: inline-block;
    height: 50px;
}
                