﻿.addnewquestionchecklist {
    float: right !important;
    cursor: pointer !important;
}
.tempaccordianstyle {
    width: 100% !important;
        border: 1px solid grey !important;
        border-radius:2px !important ;
}
.floatright{
    float:right !important;
}
.questiontable {
    width: 100% !important;
        background:#337ab7a1 !important;
}
.addquestiontitle {
    font-size: 1.25rem !important;
    color: white !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
}
.divprimary .btn-primary:hover, .divprimary .btn-primary:active {
    background: none !important;
    border: none !important;
}
.divprimary .btn-primary {
    background: none !important;
    border: none !important;
}
.labelpadding{
    padding-left:8px !important;
}
.checkquestionbuttons {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 90px;
    max-width: 90px;
    color: white;
}
.rowpaddingbottom{
    padding-bottom:6px !important;
}
.questiontablestyle{
    width:97% !important;
    

}
.rowWarning {
    background-color: #ff8181;
}
.checklistcloseicon {
    background: #dc3545 !important;
    color: #fff !important;
}
.cklclose {
    color: #fff !important;
    font-size: 14px !important;
}

.TemplateQuestionaireTableOuter {
    margin-top: 10px;
    width: 100%;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-orderlist-list {
    width: 98%;
    background-color: #ffffff00;
    border-left: 1px solid #c8c8c8 !important;
    border-top: 1px solid #c8c8c8 !important;
    border-bottom: 0px !important;
    border-right: 0px !important;
    overflow-x: hidden;
    margin-left: 1%;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight a {
    color: white;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-orderlist-controls {
    width: 2%;
}

.NoArrowOrderList .p-orderlist .p-orderlist-controls {
    display: none;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0px !important;
    font-weight: 500;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item tr > td {
    padding: 0px 0px 0px 10px !important;
    border-right: 1px solid #c8c8c8 !important;
    border-bottom: 1px solid #c8c8c8 !important;
    word-break: break-word;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    background-color: #007ad9a3;
}

.TemplateQuestionaireTableOuter .p-orderlist .p-button {
    background-color: #2e6da4 !important;
    border: #2e6da4 !important;
}

#ChecklistTemplateQuestionaireTable {
    width: 100%;
}

#ChecklistTemplateQuestionaireTable .p-orderlist-list-container {
    width: 100%;
}

#ChecklistTemplateQuestionaireTable .p-orderlist-list-container > ul > li:first-child {
    background-color: #c7e3ff !important;
    color: black !important;
    -webkit-box-shadow: inset 0 0 0 0.2em #ffffff00;
    box-shadow: inset 0 0 0 0.2em #ffffff00;
}

#ChecklistTemplateQuestionaireTable .p-orderlist-droppoint {
    display: none !important;
}

.stylingwordingOCE {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}