﻿.cardstyle {
    background-color: bisque !important;
}
.cardbodystyle {
    padding: 0.2rem !important;
    color: #148 !important;
    vertical-align:middle;
}
.headertext {
    font-size: 18px !important;
    font-weight:700;
    padding-top:3px;
}
.cardbodytext {
    font-size: 14px;
    padding: 0.4rem !important;
    color: rgba(0,0,0,0.87) !important;
}
.divpaddingtop{
    padding-top:5px;
}
.btnUserGuide {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btnStyle {
    padding-bottom: 10px;
    color: #0056b3;
}
.h5postincidentreview {
    color: #3875b2 !important;
}
.ftTGEF {
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    font-weight: 600 !important;
    width:100% !important;
}
.divaddnew{
    float:right;
}
.PIRAddbuttons {
    padding: 7px !important;
    border-radius: 4px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;   
    color: white;
    //min-width:100px;
    text-align:right;
}
.thwidth400 {
    width: 400px !important;
}
.thwidth100 {
    width: 100px !important;
}
.thwidth200 {
    width: 200px !important;
}
.thwidth120 {
    width: 120px !important;
}

.thwidth150 {
    width: 150px !important;
}
.thwidth300 {
    width: 300px !important;
}
.thwidth280 {
    width: 280px !important;
}

.tblPIR thead tr {
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}
.tblPIR thead tr th {
    text-align: center !important;
    font-size: 14px !important;
    vertical-align:middle !important;
}
.tblPIR{
    border-radius:5px !important;
    width:100%;
    
}

.tblPIR thead tr th {
    color: #000000 !important;
}
.tblPIR tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    height: 40px;
    line-height: 21px;   
    vertical-align: middle;
    word-wrap:break-word;
}

.tblPIR td {
    border: 1px solid #00000029 !important;
}
.centericons{
    text-align:center !important;
    vertical-align:middle;
}
.redcolorIcons {
    color: red !important;   
}
.bluecoloricons {
    color: #003C8D !important;   
}
.Iconsstyles {
    font-size: 15px !important;
    cursor: pointer;
}
.datepickerCalendar {
    display: none;
}

.headertext {
    color: #3269a0 !important;
    background-color: inherit;
}
.tblpadding{
    padding-top:5px !important;
}
.spnmandatoryIcons{
    color:red;
    font-weight:bold;
    font-size:17px;
}
.tdwidth20{
    width:20% !important;
}
.tdwidth16{
    width: 16% !important;
}
.tdwidth18 {
    width: 18% !important;
}
.tdwidth10 {
    width: 10% !important;
}
.recbutton{
  
    background:inherit !important;
    cursor:pointer;
    outline:none !important;
    border:none !important;
}
.perf thead tr {
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}
.tdwordbreak{
    word-break:break-word !important;
}
.clsnodatamessgefortable {
    text-align:center !important;
    vertical-align:middle !important;
    font-size:14px;
    color:red;
    font-weight:bold;
    
}

.greyicins{
    color:grey;
}

.WSHR {
    color: #3875b2 !important;
    font-size: 14px !important;
    font-weight: 700;

}
.WSHRlinksText {
    color: #3875b2 !important;
    font-size: 14px !important;
    font-weight: 700;   
    padding-top: 10px;
}
.WSHRlinks {
    color: blue;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 700;
}

.WSHRlinks:hover {
    text-decoration: underline;
    color: #0056b3;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 700;
}

.WeeklyIDSHRTableOuter {
    margin-top: 10px;
    width: 100%;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-list {
    width: 98%;
    background-color: #ffffff00;
    border-left: 1px solid #c8c8c8 !important;
    border-top: 1px solid #c8c8c8 !important;
    border-bottom: 0px !important;
    border-right: 0px !important;
    overflow-x: hidden;
    margin-left: 1%;
}
#WeeklyIDSHRTableid {
    width: 100%;
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
}

#WeeklyIDSHRTableid .p-orderlist-list-container{
    width: 100%;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight a {
    color: white;
}

#WeeklyIDSHRTableid .p-orderlist-list-container > ul > li:first-child {
    background-color: #c7e3ff !important;
    color: black !important;
    -webkit-box-shadow: inset 0 0 0 0.2em #ffffff00;
    box-shadow: inset 0 0 0 0.2em #ffffff00;    
}

#WeeklyIDSHRTableid .p-orderlist-droppoint{
    display: none !important;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-controls {
    padding-left: 0px;
}

.NoArrowOrderList .p-orderlist .p-orderlist-controls {
    display: none;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0px !important;
    font-weight: 500;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item tr > td {    
    border-right: 1px solid #c8c8c8 !important;
    border-bottom: 1px solid #c8c8c8 !important;
    word-break: break-word;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item tr{
    display: flex;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    background-color: #007ad9a3;
}

.WeeklyIDSHRTableOuter .p-orderlist .p-button {
    background-color: #2e6da4 !important;
    border: #2e6da4 !important;
}

.IDSHRTable {
    padding: 0;
    margin: auto;
    /*border-collapse: collapse;*/
    width: 100%;
    text-align: center;
    color: whitesmoke;
    border: 1px solid #dddddd !important;
}

.IDSHRTableHeaderRow {
    //background-color: #2e6da4 !important;
    background-color: #c7e3ff !important;
    font-weight: bold;
    height: 40px;
    color: white !important;
}

/*.IDSHRTableBodyRow:nth-child(odd) {
    background-color: #c7e3ff; //rgba(128, 128, 128, 0.050);
}*/

/*#latestUserScore {
    background-color: crimson;
}

.IDSHRTableBodyRow:hover {
    background-color: darkorange;
}*/

.IDSHRTable tr td {
    height: 25px;
    color: black;
    border: thin;
}

.btn:focus, .btn.focus {
    box-shadow: none;
}