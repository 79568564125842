﻿.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
//-------------------- Weekly Report -------------------------//
.tableGrid {
    border: 2px solid #12496d;
    border-collapse: collapse;
    font-family: arial;
    background:white !important;
}

.weeklytr {
    background-color: #c7e3ff !important;
    color: #707070 !important;
    font-size: 15px;
}

.weeklytd {
    border-radius: 8px;
    font-family: Helvetica;
    background-color: #337ab7;
    width: 100% !important;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboardmsg{
  color: royalblue;
  font-size: 14px;
}

.field{
   padding-left: 38px !important;
}

.collpaseicon {
    padding-top: 10px !important;
    padding-right: 20px !important;
    float: right;
}

.addmetricdatasource {
 min-height: 20px !important;
}

.metricaddbtn {
    color: #ffffff !important;
    background-color: #337ab7 !important;
    border: 1px solid #2e6da4 !important;
    width: 100px !important;
    padding-left: 14px !important;
}

.metricclearbtn {
    color: #ffffff !important;
    background-color: #337ab7 !important;
    border: 1px solid #2e6da4 !important;
    width: 100px !important;
    padding-left: 14px !important;
}

.metricclosebtn {
    color: #ffffff !important;
    background-color: #337ab7 !important;
    border: 1px solid #2e6da4 !important;
    width: 100px !important;
    border-radius: 8px;
    padding-left: 14px !important;
}

.bckweeklybtn {
    color: #ffffff !important;
    background-color: #337ab7 !important;
    border: 1px solid #2e6da4 !important;
    width: 135px !important;
    float: right;
}

.fontcolor{
    color: dimgrey !important;
    font-weight: bold !important;
}
.metricnew {
    margin-bottom: 0.2rem !important;
    font-size: 16px;
    padding-left: 10px;
}

.divAddMetric {
    border: 1px solid grey;
    border-Radius: 0.25rem;
    width: 352px;
    padding: 3px;
}
.btnStyleCollapse {
    padding-bottom: 8px;
    color: black;
}

.collapsestyle {
    height: 32px;
    float: right;
}

.searchstyleselfserve > div:last-child > div {
    display: block;
}

.p-button-text {
    float: left;
}

.weeklytrth {
    text-align: center !important;
    vertical-align: middle;
    color: #000000 !important;
    opacity: 0.8
}

.Colval{
    vertical-align: middle;
    text-align: center !important;
}

body {
    background-color: azure;
}

/*tr:nth-child(even) {
    background-color: #fafafa;
    }
        tr:nth-child(odd) {
            background-color: #dfdfdf;
        }*/
.header {
    transform: translateY(0px);
    background-repeat: repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-origin: padding-box;
    background-clip: padding-box;
    background-color: #6399cd;
    width: 100%;
    position: fixed;
    top: 10px;
    height: 80px;
    padding-top: 10px;
    left: 0px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: white !important;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 35px;
    /*box-shadow: 2px 2px 12px rgba(0,0,0,0.5);*/
    display: inline-block;
    z-index: 10;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
}

.header > td {
    font-size: 35px;
}

.filter-button {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    font-family: Helvetica;
    height: 40px;
    outline: none;
}

.report-button {
    padding: 2px !important;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    font-family: Helvetica;
    height: 30px;
    outline: none;
}

.filter-hmbutton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
}

.btn-primary:focus,
.btn-primary.focus, .filter-hmbutton:focus, .filter-hmbutton:active, btn-primary:active {
    color: #fff !important;
    background-color: #286090 !important;
    border-color: #122b40 !important;
}


.btn-primary:hover {
    color: #fff !important;
    background-color: #286090 !important;
    border-color: #204d74 !important;
}

.filterDiv {
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 1;
    position: fixed;
    top: 90px;
    background-color: azure;
}

.tableGrid {
    border: 2px;
    border:solid;
    border-color:lightgray;
    border-collapse: collapse;
    font-family: arial;
}

thead tr {
    background-color: #DEE9F5 !important;
    color: #707070;
    font-size: 14px;
}

th {
    text-align: center;
    border-right: 1px solid rgba(18, 73, 109, 0.4);
    padding: 3px;
}

th img {
    width: 20px;
    height: 20px;
}

td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
}

.blankTable {
    width: 1420px;
    text-align: center;
    color: orangered;
}

p {
    margin: 0px;
}

.kpi {
    width: 180px;
    font-weight: bold;
    border: 1px solid rgba(18, 73, 109, 0.4);
    vertical-align: middle !important;
    text-align: left !important;
    background-color: #f5ffff;
}

.img {
    width: 20px !important;
    /*border-right:1px solid #12496d;*/
    border-left: 1px solid #12496d;
    background-color: #f5ffff;
}

img {
    cursor: pointer;
}

.ml01 {
    width: 5px;
    background-color: #ebeff5;
    border: 2px solid grey;
}

.ml01:not(:empty) {
    width: 400px;
}

.ml02 {
    width: 5px;
    background-color: #f5ffff;
    border: 1px solid rgba(18, 73, 109, 0.4);
}

.ml02:not(:empty) {
    width: 150px;
}

.ml03 {
    width: 400px;
    background-color: #f5ffff;
    border: 2px solid grey;
}

.ml03:not(:empty) {
    width: 400px;
}

.showborderall {
    left: 471px;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    background-color: white;
    z-index: 2;
    box-shadow: -1px 0 0 grey;
    width: 400px;
    border: 2px solid grey;
}

.showborder_VNall {
    left: 206px;
    position: sticky;
    background-clip: padding-box;
    /* white-space: nowrap; */
    background-color: white;
    z-index: 2;
    box-shadow: -1px 0 0 grey;
    width: 400px;
    border: 2px solid grey;
}

tr {
    font-size: 14px;
}

.data {
    text-align: center !important;
    background-color: #f5ffff;
    width: 120px;
    border: 1px solid rgba(18, 73, 109, 0.4);
}

.mintdwidth {
    width: 50px;
}

.newKPI {
    border-top: 1px solid rgba(18, 73, 109, 0.4);
}

.fullborder {
    border: 1px solid rgba(18, 73, 109, 0.4);
}

.red {
    background-color: #FFD1D3; //#ffbbbb
}

.green {
    background-color: #CEF8C1; //#8df295
}

.white {
    background-color: white;
}

.grey {
    background-color: #EBEBEB;
}

.blue {
    background-color: #cef8c1;
    border: 2px solid #007ad9 !important;
}
.undoIcon{
    font-size: 10px;
    float: left;
}
.topRightTriangle {
    width: 0;
    height: 0;
    top: -2px;
    vertical-align: top;
    float: right;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-top: 8px solid red;
}

.padRight {
    color: #100;
    border: 1px solid silver;
    margin: 0 0 1.5em 0;
    box-shadow: 1px 1px 1px 1px #999;
    align-items: center;
    align-content: center;
    align-self: center;
    display: inline-block;
    padding: 3px 3% 15px 3px !important;
}

/*...Frozen First Column*/
.stickythw {
    top: -1px;
    left: 0;
    background-clip: padding-box;
    white-space: nowrap;
    z-index: 3;
    color: #707070 !important;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
    background-color: #c7e3ff !important;
}

/*...Frozen Second Column*/
.stickythCol2w {
    top: -1px;
    left: 206px;
    background-clip: padding-box;
    white-space: nowrap;
    z-index: 3;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
    background-color: #c7e3ff !important;
}


.stickythCol3w {
    top: -1px;
    left: 471px;
    background-clip: padding-box;
    white-space: nowrap;
    z-index: 3;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
    background-color: #c7e3ff !important;
}


/* Scrollable table columns except 1 and 2 which are frozen*/
.stickyTablew th:nth-child(n+4) {
    top: -1px;
    background-clip: padding-box;
    white-space: nowrap;
    background-color: #DEE9F5 !important;
    z-index: 1;
    border: 1px solid rgba(18, 73, 109, 0.4);
    background-color: #c7e3ff !important;
}

.stickytdWeekly {
    left: 0;
    background-clip: padding-box;
    /*white-space: nowrap;*/
    background-color: white;
    z-index: 2;
}

.stickytdWeeklyCol2 {
    left: 206px;
    background-clip: padding-box;
    /*white-space: nowrap;*/
    background-color: white;
    z-index: 2;
}
.stickytdWeeklyCol3 {
    left: 471px;
    background-clip: padding-box;
    z-index: 2;
}

.cm-red {
    background-color: #f7c4c4;
    color: #a80d0d;
}

.cm-green {
    background-color: #c7f6cc;
    color: #129811;
}

.cm-yellow {
    background-color: #e6e5a7;
    color: #ba7c0b;
}

.bgm-red {
    color: red;
}

.bgm-green {
    color: green;
}

.graph {
    width: 15px;
    height: 15px;
}

#table_wrapper {
    float: left;
}

#tbody {
    height: 550px;
    overflow-y: auto;
}

.ppt-modal {
    min-width: 1250px !important;
}

.ppt-modal {
    min-width: 1250px !important;
}

.ppt-def {
    text-align: center;
    min-width: 235px !important;
    width: 235px !important;
    max-width: 235px !important;
    word-wrap: break-word;
    /*max-width:400px;
            min-width:200px;*/
    vertical-align: middle;
    border: 1px solid #ddd;
}

.ppt-100 {
    text-align: center;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    word-wrap: break-word;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.ppt-dim {
    text-align: center;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    word-wrap: break-word;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.ppt-week5 {
    width: 150px;
    max-width: 150px;
    vertical-align: middle;
    border: 1px solid #ddd;
    word-wrap: break-word;
}

.ppt-week5-edit {
    width: 176px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

.ppt-comment {
    width: 177px;
    vertical-align: middle;
    border: 1px solid #ddd;
}
/*********** Sticky Header ***********/

.sticky th {
    top: -1px;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    background-color: inherit;
}

/*.sticky tr:hover {
    background-color: #e8e8e8;
}*/

.sticky th:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
/*.DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid transparent;
    position: relative;
    z-index: 1;
    min-height: 158px !important;
 }*/
/*********** END OF Sticky Header ***********/
.custom-md-3 {
    position: relative;
    min-height: 1px;
    padding-right: 3px;
    padding-left: 3px;
    width: 20%;
}

.custom-md-3-800 {
    position: relative;
    min-height: 1px;
    padding-right: 2px;
    padding-left: 2px;
    width: 35%;
}

.custom-md-3-600 {
    position: relative;
    min-height: 1px;
    padding-right: 2px;
    padding-left: 2px;
    width: 43% !important;
}

.shoppingBadge-custom {
    background: red;
    left: 10px; /** you can make changes here to change position **/
    -webkit-transform: rotate(-1deg); //40
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg);
    top: 18px;
    font-size: 8px;
    padding-left: 4px;
    padding-right: 4px;
    color:white;
}

.Darkblue {
    color: #0080D5;
    font-size: 17px;
    font-weight: bold;
    opacity: 0.9;
}

.icon-arrow-right {
    float: right;
    margin-right: 6px;
    color: #0080D5;
    font-size: 17px;
    font-weight: bold;
    opacity:0.8;
}

.Editiconsfontsize {
    font-size: 20px;
}

.textalignright {
    text-align: right !important;
}

.textaligncenter {
    text-align: center !important;
}

.textAgg {
    text-align: center !important;
    min-width:60px;
}

.wklhisth {
    text-align: center !important;
    vertical-align: middle !important;
    background-color: rgb(222, 233, 245) !important;
    color: #414141 !important;
    border-right: 1px solid #00000029 !important;
}

.txtcmnt {
    width: auto;
}

.KPIcalth {
    color: #000000 !important;
    border-right: 1px solid #00000029 !important;
    text-align: center !important;
    vertical-align: middle !important;
}

.perfth {
    text-align: center !important;
    vertical-align: middle !important;
    color: #000000 !important;
    border: 1px solid #00000029 !important;
    opacity: 0.8;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1490px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 963px;
    }
}

.note {
    position: relative;
    padding-top: 4px;
    padding-right: 4px;
}

.rowdisplay div {
    display: inline-block;
}

.rowdisplay div:hover {
    text-decoration: underline;
}

.note:after {
    content: "";
    position: absolute;
    top: -2px;
    right: 0;
    width: 0;
    height: 0;
    display: block;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-top: 8px solid #f00;
}

.Perfsubtd, .perfth {
    padding: 0.75rem !important;
}

.highcharts-credits {
    display: none !important;
}
.tblborder {
    border: 1px solid #00000029 !important;
}
#tdDailydata {
    background: rgba(234, 216, 128, 0.31) !important;
}
#tdDailydata td {
    padding: .2em 0.5em !important
}

#tdDailydata th, #tdDailydata td {
    border-left: 1px solid #00000029 !important;
    border-right: 1px solid #00000029 !important;
}
#tdDailydata thead tr, #availbilitydata thead tr {
    border: 1px solid #00000029 !important;
}
#tdDailydataW {
    background: rgba(234, 216, 128, 0.31) !important;
}

#tdDailydataW td {
    padding: .2em 0.5em !important
}

#tdDailydataW th{
    border-left: 1px solid #00000029 !important;
    border-right: 1px solid #00000029 !important;
}

#tdDailydataW thead tr, #availbilitydata thead tr {
    border: 1px solid #00000029 !important;
}

.tdvalue {
   
    font-family: Arial, Helvetica, sans-serif !important;
    text-align: center !important;
    cursor: pointer;
    font-weight: 500 !important;
    //min-width:60px;
    
}
.tdvalue:hover, .td-value:active {
    font-weight: bold !important;
    color: darkblue;
    text-decoration: underline;
}

.tdvalue-50 {
    font-family: Arial, Helvetica, sans-serif !important;
    text-align: center !important;
    cursor: pointer;
    font-weight: 500 !important;
    height:100px;
    //min-width:60px;
}

.tdvalue-50:hover, .td-value-50:active {
    font-weight: bold !important;
    color: darkblue;
    text-decoration: underline;
}


#tdDailydata th, #tblhistory th {
    color: #414141 !important;
}
#tdDailydata thead tr {
    background-color: #DEE9F5 !important ;
    font-size:12px !important;
    padding: 3px !important;
}
#tdDailydata thead tr th {
    color: #000000 !important;
}
#availbilitydata td, #Performancedata td, #Performancedata thead tr, #tblhistory thead tr{
    border: 1px solid #00000029 !important;
}

.picky__input {
    width: 100%;
    background-color: white;
    border: 0;
    border-width: 1px;
    border-color: #eee;
    border-style: solid;
    border-radius: 5px;
    padding: 4px;
    text-align: left;
    position: relative;
    box-sizing: border-box;
    height: 35px !important;
}
.datacenterwidth {
    min-width: 150px !important;
}
.weeklybgm {
    padding: 0.25rem 0.3rem;
    font-size: medium;
    color: black;
    text-decoration: none;
    font-weight: normal;
}
//====================starting of styles for Saw machine (150% scale)==========================//
@media only screen and (max-width: 1280px) and (max-width: 1536px) {

    .weeklybgm {
        padding: 0.25rem 0.3rem;
        font-size: 14px !important;
        color: black;
        text-decoration: none;
        font-weight: normal;
    }
    .fontsize14{
        font-size:x-small !important;
    }
    .fontsmall{
        font-size:small !important;
    }

    }