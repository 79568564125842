﻿.cardstyle {
    background-color: antiquewhite !important;
}

.cardbodystyle {
    padding: 0.2rem !important;
    color: #148 !important;
    vertical-align: middle;
}

.headertext {
    font-size: 18px !important;
    font-weight: 700;
    padding-top: 5px;
}

.cardbodytext {
    font-size: 14px;
    padding: 0.4rem !important;
    color: rgba(0,0,0,0.87) !important;
}

.divpaddingtop {
    padding-top: 5px;
}

.PIRheader {
  font-size: 18px !important;
  padding-left: 40px;
}

.buttondiv {
    text-align: right !important;
}

.PIRbutton {
    padding: 7px !important;
    border-radius: 4px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    color: white;
}

.fontpir{
    font-size: 14px;
}

.tblPIRLearnings thead tr {
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}

.tblPIRLearnings thead tr th {
    text-align: center !important;
    font-size: 14px !important;
    vertical-align: middle !important;
}

.tblPIRLearnings {
    border-radius: 5px !important;
    width: 100%;
}

.tblPIRLearnings thead tr th {
    color: #000000 !important;
}

.tblPIRLearnings tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.tblPIRLearnings td {
    border: 1px solid #00000029 !important;
}

.pickyPIR {
    width: 150px !important;
}

.pickyPIR > button > span {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.pickyPIR > button {
    background-color: whitesmoke;
    border-radius: 0.25rem;
}

.pickyPIR > div {
    width : 200px;
}

.pirpickydiv {
    display: flex;
    justify-content: center;
}

.trix-editorpir {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 320px !important;
    max-height: 320px !important;
    overflow-y: auto;
}

.pirlearningssaveModelbutton {
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    min-width: 75px;
    max-width: 75px;
    color: white;
}

.moreless {
    color: blue;
    cursor: pointer
}

.moreless:hover {
    text-decoration: underline;
    background-color: rgb(149,189,230);
}
.pirscrollbuttons {
    width: 150px !important;
    background-color: #337ab787 !important
}