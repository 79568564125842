﻿.shadowboxtext {
    color: #148;
    text-align: center;
}
.linkHeader {
    text-align: center;
    text-decoration: none;
    line-height: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    color: #148;
    word-break: break-all;
}
.linkbody {
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 14px;
    text-align: center;
    color: grey;
    font-family: Calibri;
}
.shadow {
  /*  width: 300px;
    height: 125px;*/
    width: 100%;
    height:100%;
    padding: 6%;
    background-color: white;  
    float: left;
    border-radius: 0.25rem;
    border-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08), 0 5px 20px 0 rgba(0, 0, 0, 0.06);
}
.shadowcol {
    /*min-height: 150px;
    max-height: 300px;*/
    margin-bottom: 1%;
}

/*//====================starting of styles for Saw machine (150% scale)==========================//
@media only screen and (max-width: 1280px) and (max-width: 1536px) {

    .shadow {
        width: 250px;
        height: 125px;
        padding: 15px;
        background-color: white;
        float: left;
        border-radius: 0.25rem;
        border-color: rgba(0, 0, 0, 0.05);
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08), 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    }
}

@media (max-width: 767px) {
    .shadow {
        width: 214px;
        height: 125px;
        padding: 15px;
        background-color: white;
        float: left;
        border-radius: 0.25rem;
        border-color: rgba(0, 0, 0, 0.05);
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08), 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    }
}

//====================starting of styles for Saw machine (100% scale)==========================//
@media (min-width: 1920px) {

    .shadow {
        width: 100%;
        height: 90%;
        padding: 15px;
        background-color: white;
        float: left;
        border-radius: 0.25rem;
        border-color: rgba(0, 0, 0, 0.05);
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.08), 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    }
}
*/