﻿
.previous {
    background-color: white;
    font-size:medium;
    font-weight:bolder;
    color:blue;
    float:left;
    
}

.current {
    background-color: white;
    text-align: center;
    font: Regular 16px/22px Segoe UI;
    font-weight:bolder;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
    cursor: default;
}

.next {
    background-color: white;
    text-align: right;
    font: Regular 16px/22px Segoe UI;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
    cursor: pointer;
}
