.PageHeader {
    color: #148;
    font-weight: bold;
    padding: 0px;
    background-color: bisque;
    margin-top: 0px;
    /*width: 70vw;
    height: 50px;*/
}

.PageContent {
    padding: 2rem 13rem !important;
}

.PageHeaderContent {    
    font-size: 18px;
}

.adotaskcreationtable .p-dropdown {
    width: 220px;
    margin: 0px !important;
}

.adotaskcreationpage table tr td{
    vertical-align: middle;
}

.adotaskcreationtable {
    border: 1px solid #dee2e6;
    width: 100%;
    align-self: center;
    border-color: #dee2e6;
}

.adotaskcreationtable table tr th {    
    font-size: 14px;
    font-weight: 700;    
}

.adotaskcreationtable table-striped tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.adotaskcreationtable table{
    border: 1px solid;
}

.adotaskcreationtable table tr td react-datepicker-wrapper react-datepicker__input-container input {
    width: 100%;
}

.DateInputStyle {
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    border: 1px solid #aeaeae;
    line-height: 22px;
    padding: 6px 10px 5px;
    width: 100%;
    font-size: 13px;
}

.input-placeholder {
    position: relative;
}

.input-placeholder input {
    padding: 10px;
    font-size: 14px;
}

.input-placeholder input:valid + .placeholder {
    display: none;
}

.placeholder {
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    height: 25px;
    font-size: 20px;
    left: 10px;
    margin: auto;
    color: #ccc;
}

.placeholder span {
    color: red;
}

.AdoModal .modal-dialog {
    max-width: 85%;
}

.AdoModal .modal-content {
    background-color: white;
}

.AdoModal table th {
    background-color: #3875b2 !important;
    border: 1px solid white;
    color: white;
    font-size: 14px;
}

.approveTask {
    border: 2px solid #d4dae1;
}

.approveTask tbody {
    border: 2px solid #d4dae1;
}

.approveTask tbody tr {
    border: 2px solid #d4dae1;
}

.approveTask tbody tr td {
    border: 2px solid #d4dae1;
}

#tags{
    width: 15% !important;
}