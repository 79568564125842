﻿.tdcenter{
    text-align: center;
}

.cardstyleAdmin {
    background-color: antiquewhite !important;
}

.newsLetterGroupDropdown {
    min-width: 300px !important;
    max-width: 300px !important;
}

.newsLetterGroupDropdown > div:last-child > div {
    display: block;
    width: 300px !important;
}

.ConfigGuide {
    color: #0056b3;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 700;
}
.ConfigGuide:hover {
    text-decoration: underline;
    color: #0056b3;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 700;
}

.cardbodystyleAdmin {
    padding: 0.2rem !important;
    color: #148 !important;
    vertical-align: middle;
}

.thwidth150 {
    width: 150px !important;
}

.thwidth120 {
    width: 120px !important;
}

.textaligncenter {
    text-align: center !important;
}

.tblHeatmapAdmin {
    border-radius: 5px !important;
    width: 100%;
}

.tblHeatmapAdmin thead tr th {
    color: #000000 !important;
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}

.tblHeatmapAdmin tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.tblHeatmapAdmin tbody tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}
.tblHeatmapAdmin td {
    border: 1px solid #00000029 !important;
}