﻿.Escalationheader {
    font-size: 18px !important;
    padding-left: 40px;
}

.headertextEscalation {
    font-size: 18px !important;
    font-weight: 700;
    padding-top: 5px;
    text-align: center;
}


.boxShadow {
    color: #100;
    border: 1px solid silver;
    margin: 0 0 1.5em 0;
    box-shadow: 1px 1px 1px 1px #999;
   // padding: 3px !important;
    align-items: center;
    align-content: center;
    align-self: center;
    display: inline-block;
}

.boxShadow {
    padding-left: 15px !important;
}

.boxShadow table {
    border: none !important;
    position: relative;
    border-collapse: collapse !important;
    margin: 0 auto;
    padding: 0 !important;
}

.escalationsearch{
    width: 100%;
    text-align: center;
}

.escalationsearchtable {
    width: 80%;
    text-align: center;
}

.searchstyle {
    width: 20%;
    text-align: right !important;
    padding-top: 25px;
    border: none;
}

.globalsearchstyle {
    width: 70%;
    padding-top: 20px;
    border: none;
}
.addrow {
    width: 10%;
    text-align: right;
    padding-top: 20px;
}

.createEscalationrow {
    cursor: pointer;
    color: green;
}


.tblIDEsclation {
    border-radius: 5px !important;
    width: 100%;
    background-color: #f0ffff !important;
}

.tblIDEsclation thead tr th {
    color: #000000 !important;
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}

.tblIDEsclation tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.tblIDEsclation tbody tr > td > div p > span {
    background-color: transparent !important;
}

.tblIDEsclation tbody tr > td > div p > a {
    background-color: transparent !important;
}

.tblIDEsclation tbody tr > td > div > ul li > span{
    background-color: transparent !important;
}


.tblIDEsclation tbody tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}

.tblIDEsclation td {
    border: 1px solid #00000029 !important;
}

.thwidth50 {
    width: 50px !important;
}

.thwidth100 {
    width: 100px !important;
}

.thwidth150 {
    width: 150px !important;
}

.thwidth200 {
    width: 200px !important;
}

.thwidth225 {
    width: 225px !important;
}

.thwidth250 {
    width: 250px !important;
}

.thwidth550 {
    width: 550px !important;
}

.tdstyle {
    text-align: left;
    word-break: break-word;
}

.tdcenter {
    text-align: center !important;
}

.trix-editorescalation {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 80px !important;
    max-height: 80px !important;
    overflow-y: auto;
}

.escalationsaveModelbutton {
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    min-width: 75px;
    max-width: 75px;
    color: white;
}

.RTELIdots ol, .RTELIdots ul, .RTELIdots dl {
    margin-top: 0px;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #212529 !important;
    font-weight: normal !important;
    margin-left: 1rem !important;
}

.RTELIdots ul li {
    list-style-type: circle !important;
}


.p-editor-content ul, .p-editor-content ol, .p-editor-content dl {
    margin: 16px 0;
    padding: 0 !important;
    color: #212529 !important;
    font-weight: normal;
}