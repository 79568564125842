.saveCancelButton {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border-radius: 7px;
    padding: 3px 13px;
}

.saveCancelButton:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-filter-demo .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.datatable-filter-demo .table-header {
    display: flex;
    justify-content: space-between;
    background-color: antiquewhite !important;
}

.datatable-filter-demo .p-datepicker {
    min-width: 25rem;
}

.datatable-filter-demo .p-datepicker td {
    font-weight: 400;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-filter-demo .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.DCbuttons {
    padding: 7px !important;
    border-radius: 4px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    color: white;
}

.DCboxShadow {
    color: #100;
    border: 0px solid;
    margin: 0 0 1rem 0;
    box-shadow: inset -1px -1px 17px 3px lightblue;
    padding: 3px !important;
    align-items: center;
    align-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
}


@media screen and (max-width: 960px) {
    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}
