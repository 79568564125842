﻿/* react-toastify NPM PACKAGE CSS OVERRIDES */
.Toastify__toast {
  border-radius: $rt-toast-border-radius;
}

/* OVERRIDES & GENERAL STYLES */
html,
body {
  background-color: $color-body-bg;
}

.box {
  padding: 1.75rem;
  border-radius: 0.25rem;
  border-color: rgba(0,0,0,.05);
  box-shadow: 0 2px 7px 0 rgba(0,0,0,.08), 0 5px 20px 0 rgba(0,0,0,.06);

  &.login-box {
    padding: 1.5rem;
  }

  &.container-box {
    min-height: 430px;
  }
}

#login-img {
  opacity: 0.9;
  padding-bottom: 0.75rem;
}

.section {
  min-height: 60rem;
}
.subsection {
    min-height: 40rem;
}

.section-login {
  padding: 1rem 1.5rem;
}

code {
  padding: 3px 4px;
  font-size: 0.94em;
  word-break: break-word;
  border-radius: 0.25rem;
  color: $color-hero-is-dark;
  background-color: rgba(9, 211, 172, 0.13);
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.hero.is-dark {
  background-color: $color-hero-is-dark;

  .hero-body {
    padding: 1.5rem;

    img {
      user-select: none;
    }
  }

  .title {
    color: $color-blue-highlight;
  }
}

.form-columns {
  h5 {
    margin-bottom: 3rem !important;
  }

  .title:not(.is-spaced) + .subtitle {
    margin-top: -1rem;
  }

  .form-control-group {
    min-height: 65px;
  }
}

.is-horizontal-center {
  align-items: center;
  justify-content: center;
}

.button {
  font-weight: 600;
  border-radius: 3px;

  &.is-light {
    background-color: rgba(9,30,66,.0725);

    &:hover {
      background-color: rgba(9, 30, 66, 0.11);
    }
  }
}

.remember-me-field {
  display: flex;

  label {
    margin-left: auto;
    margin-right: auto;
  }

  span {
    font-size: 1.02em;
  }
}

.incrementer-buttons {
  align-items: initial;
  margin-bottom: .75rem !important;

  > .button {
    margin-bottom: 0;
    min-width: 6.5rem;

    svg {
      margin: auto;
      font-size: 1.4em;
    }

    &.plus {
      svg {
        color: $cyan;
      }
    }

    &.minus {
      svg {
        color: $red;
      }
    }

    &:not(:last-child) {
      margin-right: 0.75rem !important;
    }
  }
}

.dashboard-wrapper {
  min-height: 60rem;
  padding-bottom: 3rem;

  .card-content {
    padding: 1rem 1.5rem;

    .content li + li {
      margin-top: 1em;
    }

    .dashboard-info {
      padding: 1.5em !important;
    }
  }

  hr {
    width: 50%;
    margin: 0.5rem auto;
    background-color: rgba(0, 0, 0, 0.06);
  }
}

.dashboard-link {
  color: #363636;
  font-weight: 700;
  padding: 0.25em 0.5em 0.25em;
  margin-right: 0.25em;
  transition: background-color 0.2s ease-out, border-bottom-color 0.2s ease-out;

  &.react {
    background-color: rgba(32, 156, 238, 0.18);
    border-bottom: 1px solid rgba(32, 156, 238, 0.725);

    &:hover {
      background-color: rgba(32, 156, 238, 0.3);
      border-bottom-color: rgba(32, 156, 238, 1);
    }
  }

  &.redux {
    background-color: rgba(118, 74, 188, 0.18);
    border-bottom: 1px solid rgba(118, 74, 188, 0.725);

    &:hover {
      background-color: rgba(118, 74, 188, 0.3);
      border-bottom-color: rgba(118, 74, 188, 1);
    }
  }

  &.bulma {
    background-color: rgba(0, 196, 167, 0.18);
    border-bottom: 1px solid rgba(0, 196, 167, 0.725);

    &:hover {
      background-color: rgba(0, 196, 167, 0.3);
      border-bottom-color: rgba(0, 196, 167, 1);
    }
  }

  &.aspcore {
    background-color: rgba(118, 74, 188, 0.18);
    border-bottom: 1px solid rgba(118, 74, 188, 0.725);

    &:hover {
      background-color: rgba(118, 74, 188, 0.3);
      border-bottom-color: rgba(118, 74, 188, 1);
    }
  }

  &.sass {
    background-color: rgba(198, 83, 140, 0.18);
    border-bottom: 1px solid rgba(198, 83, 140, 0.725);

    &:hover {
      background-color: rgba(198, 83, 140, 0.3);
      border-bottom-color: rgba(198, 83, 140, 1);
    }
  }

  &.typescript {
    background-color: rgba(41, 78, 128, 0.18);
    border-bottom: 1px solid rgba(41, 78, 128, 0.725);

    &:hover {
      background-color: rgba(41, 78, 128, 0.3);
      border-bottom-color: rgba(41, 78, 128, 1);
    }
  }
}

.is-pagination-group {
  .button:not(:last-child) {
    margin-right: 0.75rem !important;
  }

  > a {
    width: 8em;

    &:nth-child(1) {
      margin-left: auto;
    }

    &:nth-child(2) {
      margin-right: auto;
    }
  }
}

.icon-clickable {
  pointer-events: visible !important;
  cursor: pointer;

  &:hover {
    color: #363636 !important;
    opacity: 0.7;
  }
}

.table.is-fullwidth {
  @media only screen and (max-width: 449px) {
    font-size: 0.8rem;
  }
}


.circle-icon {
    background: #ffc0c0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 30px;
}

.icon-format {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    cursor: pointer;
    background-color: none;
    border: solid;
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: white;
}
.icon-format-config {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    cursor: pointer;
    background-color: none;
    height: 25px;
    width: 25px !important;
    border-radius: 8px !important;
    background-color: white;
}

.icon-format-red {
    padding: 0.1rem 0.3rem !important;
    opacity: 1;
    color: white;
    cursor: pointer;
    border: solid;
    border-color: rgb(255, 199, 206);
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: rgb(255, 199, 206);
}
.icon-format-red-invert {
    padding: 0.1rem 0.3rem !important;
    opacity: 1;
    color: rgb(255, 199, 206);
    cursor: pointer;
    border: solid;
    border-color: rgb(255, 199, 206);
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: white;
}

.icon-format-blue {
    padding: 0.1rem 0.3rem !important;
    opacity: 1;
    color: white;
    cursor: pointer;
    border: solid;
    border-color: #0080D5;
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: #0080D5;
}

.icon-format-yellow {
    padding: 0.1rem 0.3rem !important;
    opacity: 1;
    color: white;
    cursor: pointer;
    border: solid;
    border-color: #ffc000;
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: #ffc000;
}
.icon-format-yellow-invert {
    padding: 0.1rem 0.3rem !important;
    opacity: 1;
    color: rgb(255, 192, 0);
    cursor: pointer;
    border: solid;
    border-color: #ffc000;
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: white;
}

.icon-format-history {
    padding: 0.1rem 0.3rem !important;
    opacity: 1;
    color: white;
    cursor: pointer;
    border: solid;
    border-color: #0080D5;
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: #0080D5;
}

.icon-format-history-invert {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    cursor: pointer;
    background-color: none;
    border: solid;
    height: 25px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: white;
}

.icon-format-graph {
    padding: 0.1rem 0.3rem !important;
    cursor: pointer;
    height: 23px;
    width: 35px !important;
    border-radius: 8px !important;
    background-color: white;
    float: right;
    margin-right: 6px;
    color: darkcyan;
    font-size: 17px;
    font-weight: bold;
    opacity: 0.8;
}
.icon-newsletter-legend-green {
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: green;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8;
}

.icon-newsletter-legend-orangered {
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: orangered;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8;
}
.icon-format-legend-green {
    
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: lightgreen;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8;
}
.icon-format-legend-red {
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: lightpink;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8;

}
.icon-format-legend-gray {
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: lightgray;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8
}

.icon-format-areapth-legend-red {
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: red;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8;
}

.icon-format-icon-areapth-legend-purple {
    height: 12px;
    width: 12px !important;
    border-radius: 8px !important;
    color: purple;
    font-size: 8px;
    font-weight: bold;
    opacity: 0.8;
}

.legend-format {
    font-size: small;
    font-weight: bold;
    opacity: 0.8;
}

.legend-format-hyperlink {
    font-size: small;
    font-weight: bold;
    opacity: 0.8;
    color: #0080D5;
}

.button-format {
    border-radius: 4px;
    color: white !important;    
    background-color: #2e6da4;
    vertical-align: middle;
    border: 2px;
    border-color: #2e6da4;
    padding: 0.25rem 0.3rem;
    font-size: medium;
    text-decoration: none;
    font-weight: normal
}

.commonModalHeader {
    background-color: #6399cd !important;
    color:white !important;
}

.trix-editor-comments {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 100px !important;
  
    overflow-y: auto;
}
.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
    overflow: auto;
    min-height:100px !important;
}
.public-DraftStyleDefault-ul, .public-DraftStyleDefault-ol {
    margin: 16px 0;
    padding: 0;
    color: #212529 !important;
    font-weight: normal;
}

.paddingrightval {
    padding-right: 0 !important;
    padding-left: 20px !important;
    padding-top: 3PX !important;
}

.paddingrightleft {
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 3PX !important;
}

.paddingFilterIcons {
    padding-top:8px;
}

.paddingFilterIconAnchor {
    text-align: right;
    font-size: medium !important;
    color: black !important;
    text-decoration: none !important;
    font-weight: normal !important;
    vertical-align: top;
}

.navAnchor {
    padding: 0.25rem 0.3rem !important;
    font-size: medium !important;
    color: black !important;
    text-decoration: none !important;
    font-weight: normal !important;
    vertical-align: top;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.picky__dropdown {
    animation: fade 0.4s ease-in-out !important;
}

.react-datepicker {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 0.9rem !important;
    background-color: white !important;
    color: black !important;
    /* border: 3px solid #469fdb !important; */
    border-radius: 0.3rem !important;
    display: inline-block !important;
    position: relative !important;
}
.react-datepicker__header {
    text-align: center !important;
    background-color: #aac0d6 !important;
    /* border-bottom: 1px solid #aeaeae !important; */
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
    /* padding-top: 8px !important; */
    position: relative !important;
}
.react-datepicker__current-month {
    margin-top: 0 !important;
    color: #003c8d !important;
    font-weight: bold !important;
    font-size: 0.944rem !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: black !important;
    display: inline-block !important;
    width: 1.7rem !important;
    line-height: 1.7rem !important;
    text-align: center !important;
    margin: 0.166rem !important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled {
    cursor: default !important;
    color: #ccccccb3 !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff !important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
    border-radius: 0.4rem !important;
    background-color: #c7e3ff !important;
    color: #003c8d !important;
    font-weight: bold !important;
}
.react-datepicker-popper {
    animation: fade 0.4s ease-in-out !important;
    /*height: 270px !important;
    overflow: auto !important;*/
    border-radius: 0.3em !important;
}
.react-datepicker-popper ::-webkit-scrollbar {
    display: none !important;
}
.react-datepicker__navigation--previous {
    border-right-color: #003c8d !important;
}
.react-datepicker__navigation--next {
    border-left-color: #003c8d !important;
}

/**Remove border highlights on clicked elements**/
*:focus {
    outline: none !important;
}

.footerAll {
    position:fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color:white;
    color: white;
    text-align: center;   
    padding: .5rem 1.5rem .5rem
}

.topMenu_dropdown_divider {
    /* height: 10px; */
    margin: 1rem 0;
    overflow: hidden;
    border-top: 1px solid lightgray !important;
    /* color: red; */
}
.tooltipLS {
    position: relative;
    display: inline-block;
   
}

.tooltipLS .tooltiptextLS {
    visibility: hidden;
    width: 150px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipLS .tooltiptextLS::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipLS:hover .tooltiptextLS {
    visibility: visible;
    opacity: 1;
}

.p-datatable-thead th {
    background-color: #c7e3ff !important;
    color: #000000 !important;
    text-align: center !important;
    font-size: 14px !important;
    vertical-align: middle !important;
}

.p-datatable-thead tr {
    background-color: #F0FFFF !important;
    border: 1px solid #00000029 !important;
}

.p-datatable-tbody tr {
    background-color: #F0FFFF !important;
    border: 1px solid #00000029 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
}
.p-dialog .p-dialog-titlebar {
    background-color: #6399cd !important;
    color: #fff !important;
    text-align: center;
}

.p-dialog .p-dialog-content {
    background-color: #F0FFFF !important;
    overflow-y: inherit !important;
}

.p-dialog .p-dialog-footer {
    background-color: #F0FFFF !important;
}

.p-dialog-title {
    font-size: 1.5rem;
    font: bold;
}

/** {
    margin: 0;
    padding : 0;
    border: 0;
}*/

@keyframes slide {
    from {
        left: 100%;
    }

    to {
        left: -100%;
    }
}

@-webkit-keyframes slide {
    from {
        left: 100%;
    }

    to {
        left: -100%;
    }
}

#marquee {
    color: red;
    background: #EFFEFE;
    width: 100%;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    position: relative;
}

#text {
    position: absolute;
    top: 10;
    left: 0;
    width: 100%;
    height: 50px;
    font-size: 20px;
    animation-name: slide;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: slide;
    -webkit-animation-duration: 20s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}