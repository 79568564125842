﻿.createCopyBtn {
    margin: 22% 10% 10% 30%;
    width: 50%;
    padding: 2px !important;
    border-radius: 8px;
    border-color: rgb(46, 109, 164) !important;
    outline: none;
    min-width: 240px !important;
    max-width: 240px !important;
    height: 40px;
    background-color: #2e6da4;
    color: white;
}


.searchWorkItemButton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: rgb(46, 109, 164) !important;
    outline: none;
    min-width: 190px !important;
    max-width: 190px !important;
    height: 40px;
    background-color: #2e6da4;
    color: white;
}

.clearWorkItemButton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 150px;
    max-width: 150px;
    color: white;
    height: 40px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    max-height: 100% !important;
}

.errorMsgHeader {
    color: white;
    background-color: #65a865;
    border: 1px px solid;
}
