.PageHeader {
    color: #148;
    font-weight: bold;
    padding: 15px;
    background-color: bisque;
    margin-top: 0px;
    width: 100%;
    height: 50px;
    text-align: center;
}
.PageContent {
    padding: 2rem 13rem !important;
}
.PageHeaderContent {    
    font-size: 18px;
}
.react-checkbox-tree ol {
    color: blue
}
/*.rct-node-icon {
    display: none
}
.rct-collapse.rct-collapse-btn {
    padding: 0px 3.1px !important;
}
.rct-collapse {
    padding: 0px 0px !important;
}
.react-dropdown-tree-select{
    color: black !important
}*/
.react-dropdown-tree-select .dropdown .dropdown-content ul {
    color: black;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    max-height: 350px;
    overflow-y: auto;
    z-index: 12 !important;
    min-width: 300px;
}

.customRichEdit {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 14px;
}

.trix-editorADO {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 320px !important;
    max-height: 320px !important;
    overflow-y: auto;
    font-size: 14px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    background-color: white;
    min-height: 40px !important;
    max-height: 70px !important;
    min-width: 100% !important;
    max-width: 100% !important;
}

.createTask {
    text-align: center;
}

.createTaskButton {
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    min-width: 106px;
    max-width: 106px;
    color: white;
}

/*.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    position: absolute;
    top: 10px;
    left: 175px;
}

.search {
    position: absolute;
    top: 10px;
    left: 10px;
}*/