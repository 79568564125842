﻿cardstyleuser {
    background-color: antiquewhite !important;
}

.cardbodystyleuser {
    padding: 0.2rem !important;
    color: #148 !important;
    vertical-align: middle;
    height: 50px;
    background-color: antiquewhite !important;
}

.headertextuser {
    font-size: 18px !important;
    font-weight: 700;
    padding-top: 12px;
}

.cardbodytextuser {
    font-size: 14px;
    padding: 0.4rem !important;
    color: rgba(0,0,0,0.87) !important;
}

.divpaddingtopuser {
    padding-top: 5px;
}

.UserScenarioHeader {
    font-size: 18px !important;
    text-align: center;
}

.hrstyle {
    color: #99CC99;
    width: 100vw;
}

.tblpaddinguser {
    padding-top: 20px !important;
}

.rowstyle1 {
    width: 5vw;
    font-Size: 9pt;
}

.rowstyle2 {
    width: 10vw;
    font-Size: 9pt;
}

.stylingheader {
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
}


.tblUserScenario {
    border-radius: 5px !important;
    width: 100%;
}

.tblUserScenario thead tr th {
    color: #000000 !important;
    text-align: center !important;
}

.tblUserScenario thead tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05) !important;
    font-size: 14px !important;
}

.tblUserScenario thead tr:nth-child(even) {
    background-color: azure !important;
    font-size: 11px !important;
}

.tblUserScenario tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.tblUserScenario tbody tr:nth-child(odd), .tblUserScenario thead tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}

.tblUserScenario td, .tblUserScenario th {
    border: 1px solid #00000029 !important;
    vertical-align: middle !important;
}

.hreflink {
    font-weight: 600 !important;
    color: #007bff;
    text-decoration: none;
}

.hreflink:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
}