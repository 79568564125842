﻿.monthlydatatable {
    width: 100%;
    overflow: auto;
    display: block;
}

.monthlydatatr {
    padding: 3px;
    background-color: #c7e3ff !important;
    color: #707070 !important;
    font-size: 15px;
}

.serviceHeader {
    display: block;
    width: 200px;
    text-align: center;
    color: #000000 !important;
}

.viewHeader {
    display: block;
    text-align: center;
    color: #000000 !important;
}

.targetHeader {
    display: block;
    text-align: center;
    color: #000000 !important
}

.tableheaderVal {
    color: #000000 !important;
    text-align: center;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
}

.tabletdVal {
    background-color: #f5ffff;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
}

/*...Frozen First Column*/
.stickyth {
    top: -1px;
    left: 0;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    z-index: 3;
    color: #707070 !important;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
    background-color: #c7e3ff !important;
}

/*...Frozen Second Column*/
.stickythCol2 {
    top: -1px;
    left: 206px;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    z-index: 3;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
    background-color: #c7e3ff !important;
}


.stickythCol3 {
    top: -1px;
    left: 471px;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    z-index: 3;
    border: 1px solid rgba(18, 73, 109, 0.4) !important;
    background-color: #c7e3ff !important;
}

.stickytdCol3 {
    left: 471px;
    position: sticky;
    background-clip: padding-box;
    z-index: 2;
}

/* Scrollable table columns except 1 and 2 which are frozen*/
.stickyTable th:nth-child(n+4) {
    top: -1px;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    background-color: #DEE9F5 !important;
    z-index: 1;
    border: 1px solid rgba(18, 73, 109, 0.4);
    background-color: #c7e3ff !important;
}

//====================starting of styles for Saw machine (150% scale)==========================//
@media only screen and (max-width: 1280px) and (max-width: 1536px) {
    .stickythCol3 {
        position: inherit;
    }

    .stickytdCol3 {
        position: inherit;
    }
}
//====================end of styles for Saw machine (150% scale)==========================//