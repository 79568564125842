﻿.monthlytd {
    border-radius: 8px;
    font-family: Helvetica;
    background-color: #337ab7;
    width:100% !important;
}
.aligntable{
    
    padding-top:5px !important;
   
   
}


.stickyQOSTable th {
    top: -1px;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
    background-color: #c7e3ff !important;
    color: #000000 !important;
    z-index: 1;
    border-right: 1px solid grey !important;
}



/*...Frozen First Column*/
.stickyQOSth {
    top: -1px;
    left: 0;
    position: sticky;
    background-clip: padding-box;
    white-space: nowrap;
   
    z-index: 3;
    border-right: 0px solid white;
}

.stickyQOStd {
    left: 0;
    position: sticky;
    background-clip: padding-box;
    /*white-space: nowrap;*/
    background-color: white;
    z-index: 2;
    border-right: 0px solid grey;
}

.borderleftbottom {
    border-right: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
}
.borderbottom {
    border: 2px solid grey !important;
}
.qostable {
    width: auto !important;
    overflow: auto !important;
    display: block !important;
    height: 75vh !important;
    background:none !important
}

#tdqosdata{
    border:none !important;
}
#tdqosdata td {
    padding: .2em 0.5em !important
}

#tdqosdata th, #tdDailydata td {
    border-left: 1px solid #00000029 !important;
    border-right: 1px solid #00000029 !important;
}

#tdqosdata thead tr, #availbilitydata thead tr {
    border: 1px solid #00000029 !important;
}
#tdqosdata th {
    color: #414141 !important;
    font-size:14px !important;
}

#tdqosdata thead tr {
    background-color: #DEE9F5 !important;
    font-size: 12px !important;
    padding: 3px !important;
}

#tdqosdata thead tr th {
    color: #000000 !important;
}

#tdqosdata td {
    border: 1px solid #00000029 !important;
}
