﻿.prevservice {
    font-size: medium;
    font-weight: bold;
    color: #0080D5 !important;
    opacity: 0.7;
    float: left;
}
.prevservicedisabled {
    font-size: medium;
    font-weight: bold;
    color: grey !important;
    opacity: 0.7;
    float: left;
   
}
.nextservice {
    font-size: medium;
    font-weight: bold;
    color: #0080D5 !important;
    opacity: 0.7;
    float: right;
}
.currentservice {
    font-size: medium !important;
    font-weight: bold !important;
    color: #000000 !important;
    opacity: 0.7 !important;
    text-align: center !important;
}

.nextservicedisabled {
    font-size: medium !important;
    font-weight: bold !important;
    color: grey !important;
    opacity: 0.7;
    float: right;
    
}
.viewlabel{
    padding-top:6px !important;
    float:right!important;
}
.container2 {
    border-radius: 8px !important;
     width: 100%;
     padding: 0;
     background: #e7e7e9;
}
.paddingtop8px{
    padding-top:8px !important;
}
.paddingleft0{
    padding-left:0px !important;
}

.leftrighticons {
    font-size: 20px;
    font-weight: bold;
    color: #0080D5;
    vertical-align: middle;
}


.regionwidth {
    min-width: 150px !important;
    max-width: 250px !important;
    word-wrap:break-word;
}
.Mserheatmapcontainer {
    display: flex;
    width:100%;
}

.scroll {
    width: auto !important;
    overflow-x: auto;
}

.fixed {
    border-spacing: 0;
}

.fixed thead tr {
    background-color: #f07e7e;
    height: 30px;
}

.scrollable thead tr {
    background-color: #f07e7e;
    height: 30px;
}

.scrollable thead {
    background-color: #f07e7e;
}

.scrollable, .fixed {
    background: rgba(234,216,128,.31) !important;
    border-spacing: 0;
}

.scrollable tbody tr td {
    text-align:center !important;
   
}
.fixed thead tr th, .fixed tbody tr td {
    white-space: nowrap !important;
}
.mserheatmapdata{
    width:auto !important;
}
.onboardedtoggle, .onboardedtoggle:active, .onboardedtoggle:hover, .onboardedtoggle:disabled {
    background: #c7e3ff !important;
    border: none !important;
    color: black !important;
}
button.onboardedtoggle.btn.btn-primary, .btn-primary:focus, .btn-primary:focus {
    background: #c7e3ff !important;
    color: black !important;
}
.btn-primary.disabled, .btn-primary:disabled {
    background: #c7e3ff !important;
    color: black !important;
}
.onboardAccordian {
    width: 100%;
    border: 0.5px solid grey;
}
.onboardacccard {
    border-radius: 1px !important;
    width: 100% !important;
    padding: 0 !important;
    background: #c7e3ff !important;
    max-height: 40px !important;
}
.onboarddiv {
    max-height: 300px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding-left:10px !important
}
.onboardtext, .onboarddata {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size:15px !important;
}
.textalignright{
    text-align:right !important;
}

.textalignleft {
    text-align: left !important;
}

.sticky-scroller_MSER {
    margin-left: 261px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-scroller_vol_MSER {
    margin-left: 330px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-scroller-20_MSER {
    margin-left: 450px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-scroller-20_vol_MSER {
    margin-left: 490px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-scroller-50_MSER {
    margin-left: 640px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-scroller-50_vol_MSER {
    margin-left: 690px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-table .sticky-fixed-col-th {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 120px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col-th-20 {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 349px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col-th-50 {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 551px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col2-th_MSER {
    left: 149px;
    position: absolute;
    top: auto;
    width: 114px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col2-th_vol_MSER {
    left: 149px;
    position: absolute;
    top: auto;
    width: 192px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col2-th-20_MSER {
    left: 350px;
    position: absolute;
    top: auto;
    width: 102px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col2-th-20_vol_MSER {
    left: 390px;
    position: absolute;
    top: auto;
    width: 162px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col2-th-50_MSER {
    left: 550px;
    position: absolute;
    top: auto;
    width: 91px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
    //border-right:solid !important;
}

.sticky-table .sticky-fixed-col2-th-50_vol_MSER {
    left: 550px;
    position: absolute;
    top: auto;
    width: 161px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
    //border-right:solid !important;
}


.sticky-table .sticky-fixed-col {
    left: 0;
    position: absolute;
    text-align: left !important;
    word-break: break-word;
    top: auto;
    margin: auto;
    width: 120px;
    height: 100px;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    //max-width: 120px;
}

.sticky-table .sticky-fixed-col-20 {
    left: 0;
    position: absolute;
    text-align: left !important;
    word-break: break-word;
    top: auto;
    margin: auto;
    width: 350px;
    height: 100px;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    //max-width: 120px;
}

.sticky-table .sticky-fixed-col-50 {
    left: 0;
    position: absolute;
    text-align: left !important;
    word-break: break-word;
    top: auto;
    margin: auto;
    width: 551px;
    height: 100px;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    //max-width: 120px;
}


.sticky-table .sticky-fixed-col2_MSER {
    left: 149px;
    position: absolute;
    width: 114px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}

.sticky-table .sticky-fixed-col2_vol_MSER {
    left: 149px;
    position: absolute;
    width: 192px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}

.sticky-table .sticky-fixed-col2-20_MSER {
    left: 349px;
    position: absolute;
    width: 102px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}

.sticky-table .sticky-fixed-col2-20_vol_MSER {
    left: 349px;
    position: absolute;
    width: 162px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}

.sticky-table .sticky-fixed-col2-50_MSER {
    left: 550px;
    position: absolute;
    width: 91px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}

.sticky-table .sticky-fixed-col2-50_vol_MSER {
    left: 550px;
    position: absolute;
    width: 161px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}
.MSERHeaddings {
    font-size: 1.2vw !important;
    font-weight: 700 !important;
    background: #c7e3ff !important;
}

#tdMSERdata {
    background: #f5ffff !important,
}

#tdMSERdata td {
    padding: .2em 0.5em !important
}

#tdMSERdata th, #tdMSERdata td {
    border-left: 1px solid #00000029 !important;
    border-right: 1px solid #00000029 !important;
    font-size: 13px !important;
  
    
}

#tdMSERdata thead tr, #MSERavailbilitydata thead tr {
    border: 1px solid #00000029 !important;
}

.MSERtdData {
    text-align: center !important;
    background-color: rgb(206, 248, 193);
    font-size: 13px;
    font-weight: 500;
    border: solid 1px rgba(18, 73, 109, 0.4);
    //textAlign: 'center', backgroundColor: 'rgb(206, 248, 193)', fontSize: '13px', fontWeight: '500', border: '1px solid rgba(18, 73, 109, 0.4)'
}
.thcolor {
    width: 200px;
    text-align: center;
    color: rgb(0, 0, 0) !important;
    display: block;
    border-top: none !important;
    border-bottom: none !important;
    text-align:center !important;

}
.thsticky2column {
    display: block;
    text-align: center !important;
    color: #000000 !important;
}
/*#mlservicegroup, #mlservice, #classification, #mlservicetype, #mllifecycletype, #mlservicering, #mlidclassification, #mlacktype {
    width: -webkit-fill-available !important;
}*/
#classification{
    //line-height:28px !important;
}
.trendcolumns{
    padding-left:0px !important;
    padding-right:0px !important;
    padding-bottom :0px !important;
}
#searchheight{
    height:36px !important;
}
.boxShadow {
    color: #100;
    /* background-color: #f9f9f9; */
    border: 1px solid silver;
    margin: 0 0 1.5em 0;
    /* overflow: auto; */
    box-shadow: 1px 1px 1px 1px #999;
    padding: 3px !important;
    align-items: center;
    align-content: center;
    align-self: center;
    display: inline-block;
}
.p-multiselect .p-multiselect-label-container {
    overflow: hidden;
    line-height: 14px !important;
}
