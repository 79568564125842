﻿/* BULMA COLOR OVERRIDES */
$red: #e93e60;
$cyan: #09d3ac;
$blue: $cyan;

/* COLOR */
$color-body-bg: #f7f7f7;
$color-nav-bar: #33363b;
$color-hero-is-dark: #1f2227;
$color-blue-highlight: $cyan;

/* REACT-TOASTIFY OVERRIDES */
$rt-toast-min-height: 78px;
$rt-toast-border-radius: 3px;
$rt-color-info: #3298dc;
$rt-color-error: $red;

/* LANDING PAGE AND SERVICE HEALTH COLORS*/
$font-stack: Sergoe UI, Regular;
$primary-color: #333;

/* MIXINS */
@mixin renderTabletNavView {
    @media only screen and (max-width: 950px) and (min-width: 600px) {
        @content;
    }
}

@mixin renderMobileNavView {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin removeNavBarPadding {
  @media only screen and (max-width: 1099px) {
    @content;
  }
}

@mixin reduceNavBarPadding {
  @media only screen and (max-width: 1472px) and (min-width: 1100px) {
    @content;
  }
}
