﻿.availabilitysummaryth {
    width: 100px;
    color: #000000 !important;
    text-align: center !important;
    background-color: #DEE9F5 !important;
}

.availabilitysummaryserviceheader {
    width: 150px;
    color: #000000 !important;
    text-align: center !important;
    background-color: #DEE9F5 !important;
}

.availabilitysummarytable {
    width: 98%;
    align-self: center;
    border: 1px solid rgba(18, 73, 109, 0.4) !important; 
}

.availabilitysummaryServicetd {
    font-weight: bold;
    text-align: left;
    padding-left: 4px;
}

.serviceheader {
    color: rgb(0, 128, 213);
    opacity: 0.8;
}