﻿.dropdownstylingInternalEscalation{
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    padding-right: 0.5rem;
}

.icon-format-internalEscalation {
    color: black;
    cursor: pointer;
    background-color: none;
    height: 30px;
    width: 30px !important;
}

.icon-format-Download {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    cursor: pointer;
    background-color: none;
    border: solid;
    height: 30px;
    width: 30px !important;
    border-radius: 8px !important;
    background-color: white;
}

.icon-format-filterinternalEscalation {
    opacity: 0.7;
    color: black;
    cursor: pointer;
    background-color: none;
    height: 50px;
    width: 30px !important;
    border: solid;
    border-radius: 8px !important;
    background-color: white;
}

.datatableinternalEscalation > div > table > tbody > tr {
    background-color: #ffffff !important;
    font-size: 12px !important;
}

    .datatableinternalEscalation > div > table > tbody > tr > td  {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid #c8c8c8;
}

.datatableinternalEscalationExpanded > div > div > table > thead {
    display: none;
}

.datatableinternalEscalationExpanded > div > div > table > tbody > tr > td {
    background-color: #ffffff !important;
    font-size: 12px !important;
    border: none !important;
}

.icon-format-expandinternalEscalation {
    opacity: 0.7;
    color: green;
    cursor: pointer;
    background-color: none;
    border: solid;
    height: 33px;
    width: 37px !important;
    border-radius: 8px !important;
    background-color: white;
}

.icon-format-collapseinternalEscalation {
    opacity: 0.7;
    color: red;
    cursor: pointer;
    background-color: none;
    border: solid;
    height: 33px;
    width: 37px !important;
    border-radius: 8px !important;
    background-color: white;
}
