﻿.alignrightitems{
    float:right !important;
}
.btnwidth150{
    width:150px !important;
}
.txtcolorwhite{
    color: white !important;
}
.thwidth5 {
    width: 5% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thwidth4 {
    width: 4% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thwidth8 {
    width: 8% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thwidth10 {
    width: 10% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thwidth11 {
    width: 11% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thwidth12 {
    width: 12% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thwidth20 {
    width: 20% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.tblAcktemplate {
    width: 100% !important;
    border-collapse: separate;
    // border-color: grey !important;
}
.tblAcktemplate thead tr {
    background-color: #c7e3ff !important;
 /*   border: 1px solid grey !important;*/
}
.tblAcktemplate thead tr th {
    padding: 6px !important;
    //border: 1px solid darkgrey !important;
}
.acktextalignment {
    text-align: left;
    word-wrap: break-word;
}
.ackListtextalignment {
    text-align: left !important;
    word-wrap: break-word;
}
.tblAcktemplate td {
    border: 1px solid #00000029 !important;
    //border: 1px solid darkgrey !important;
    padding: 5px !important;
    height: 40px !important;
    height: 40px !important;
}
.editiconstyle{
    text-align:center !important;
    vertical-align:middle !important;
}
.mandatorycolor{
    color:red !important;
}

#ddlidacktype, #ddltemplate, #servicetreecollist, #ddlcontroltype, #colourpallete, #ddtempNameList {
    width: 100% !important;
}
#ddlidackdropdowntype{
    width: 35% !important;
}
.acklables > input:focus ~ label, .acklables > input.p-filled ~ label, .acklables > .p-inputwrapper-focus ~ label, .acklables > .p-inputwrapper-filled ~ label {
   
    display: none;
}
.acklabletxt{
    font-weight:normal !important;
}
.css-df17o1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color: #FFF;
    background: rgba(0,0,0,0.7);
    z-index: 1060 !important;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
    opacity: 1;
}
.errotextstyle {
    font-size: 11px;
    color: red;
}
.errotextBGstyle {
    font-size: 11px;
    color: red;
    font-weight: bold;
}
.columnsheader {
    text-align: center;
    font-weight: bold;
}
.redcolourtxt{
    color:red !important;
}
.ackcolumninputtext {
    width: 170px;
    border-radius: .25rem;
    border: 1px solid #ced4da
}
.ackcolumndisplayorder {
    width: 100px;
    border-radius: .25rem;
    border: 1px solid #ced4da
}
.txtcontrolvalues {
    /* display: block; */
  
    height: calc(1.5em + 0.75rem + 2px);
   
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.colouringheader {
    text-align: left;
    font-weight: bold;
}
.colouringtdstyle {
    text-align: center;
    width: 200px;
}
.colouringtdtext {
    width: 185px;
}
@media (min-width: 1200px) {
    #Ackmodelpopup {
        max-width: 1300px !important;
    }
 }
@media (min-width: 576px) {
   .fontsizeinsaw14{
       font-size:14px !important;
   }
}
button.onboardedtoggle.btn.btn-primary, .btn-primary:focus, .btn-primary:focus {
    color: #fff !important;
    background-color: #286090 !important;
    border-color: #122b40 !important;
}
#Ackmodelpopup{
    background:white !important;
}
#acktemplatepopup {
    max-height: 150px !important;
    min-width: 800px !important;
    max-width: 1153px !important;
}
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.229em 0.257em;
    border: 0 none;
    color: #333333;
    background-color: transparent;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    border-radius: 0;
}
.ackdeleteicon {
    background-color:none;
border: none !important;
    color: red !important;
    cursor:pointer !important;
}
.ackaddcolumnicon{
    background:none !important;
    border:none !important;
}
.ackediticon {
    background: none !important;
    border: none !important;
    cursor: pointer !important;
}
.acktrackingheadtr {
    background-color: #98afdd !important;
    word-wrap: break-word !important;
}




.ackdropdownpadding{
    padding-top:1.5rem !important;
}
.ackbtnheight{
    height:35px !important;
}
.btnwidth60 {
    width: 60px !important;
}

.btnwidth100 {
    width: 100px !important;
}
.ackiconstyles{
    font-size:20px !important;
}
.p-multiselect{
    width:100% !important;
}
.zeropadding{
    padding:0 !important;
}
.ackdropdownpaddingicons {
    padding-top: 1.8rem !important;
    font-size: 18px !important;
}
.iconfont {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    background-color: none;
    border: solid;
    height: 30px;
    width: 34px !important;
    border-radius: 8px !important;
    background-color: white;
    cursor:pointer !important;
}
.tblwidth100{
    width:100% !important;
}
.tdwidth15{
    width:15% !important;
}
.tdwidth5 {
    width: 5% !important;
}
.tdwidth80 {
    width: 80% !important;
}
.copyemailicons {
    cursor: pointer !important;
    color: green !important;
}
.tdheight20{
    height:20px !important;
}
#tblAcktemplate thead tr th.ascending::after {
    content: '▼';
    display: inline-block;
   
}

#tblAcktemplate thead tr th.descending::after {
    content: '▲';
    display: inline-block;
   
}
.ackediticon {
    background-color: none;
    border: none !important;
    cursor: pointer !important;
}
.acktrackingheadtr {
    background-color: #98afdd !important;
    word-wrap: break-word !important;
}


/*********** Sticky Header ***********/

.acktrackingsticky th {
    top: 0px;
    position: sticky;
    background-clip: padding-box;
    //white-space: nowrap;
    //background-color: inherit;
    text-align: left !important;
    //z-index: 30 !important;
    //border: 1px solid black;
    color: white;    
    font-weight: 600;
    background-color: #1c648c !important;    
}

.acktrackingsticky tr:hover {
    background-color: #e8e8e8;
}

.acktrackingsticky th:after {
    content: '';
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
}

/*.acktrackingsticky tr:nth-child(even) td[scope=row]{
    background-color: #f2f2f2;
}

.acktrackingsticky tr:nth-child(odd) td[scope=row] {
    background-color: white;
}

.acktrackingsticky tr:nth-child(even) {
    background-color: #f2f2f2;
}*/

.thackeditstyle {
    min-width: 50px;
    max-width: 50px;
    text-align: center !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackothercolstyle {
    min-width: 100px;
    max-width: 100px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackcustomcolstyle-60 {
    min-width: 60px;
    max-width: 60px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.thackcustomcolstyle-70 {
    min-width: 70px;
    max-width: 70px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackcustomcolstyle-90 {
    min-width: 90px;
    max-width: 90px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackcustomcolstyle-110 {
    min-width: 110px;
    max-width: 110px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackcustomcolstyle-130 {
    min-width: 130px;
    max-width: 130px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackcustomcolstyle-150 {
    min-width: 150px;
    max-width: 150px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackGEMFixedcolstyle {
    min-width: 175px;
    max-width: 175px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;

}
.thackSeviceFixedcolstyle {
    min-width: 275px;
    max-width: 375px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.IDAck-fixedHeader {
    z-index: 100 !important;
}

.IDAck-fixedColHeader {
    z-index: 101 !important;
}

.IDAckboxShadow {
    color: #100;
    border: 0px solid;
    margin: 0 0 1rem 0;
    box-shadow: inset -1px -1px 17px 3px lightblue;
    padding: 3px !important;
    align-items: center;
    align-content: center;
    align-self: center;
    display: inline-block;
}
/*********** END OF Sticky Header ***********/

.ackdropdownpadding{
    padding-top:1.5rem !important;
}
.ackbtnheight{
    height:35px !important;
}
.btnwidth60 {
    width: 60px !important;
}

.btnwidth100 {
    width: 100px !important;
}
.ackiconstyles{
    font-size:20px !important;
}
.p-multiselect{
    width:100% !important;
}
.zeropadding{
    padding:0 !important;
}
.ackdropdownpaddingicons {
    padding-top: 1.8rem !important;
    font-size: 18px !important;
}
.iconfont {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    background-color: none;
    border: solid;
    height: 30px;
    width: 34px !important;
    border-radius: 8px !important;
    background-color: white;
    cursor:pointer !important;
}
.iconfontDisabled {
    padding: 0.1rem 0.3rem !important;
    opacity: 0.7;
    color: #0080D5;
    background-color: none;
    border: solid;
    height: 30px;
    width: 34px !important;
    border-radius: 8px !important;
    background-color: white;    
    cursor: not-allowed !important;
}

.tblwidth100{
    width:100% !important;
}
.tdwidth15{
    width:15% !important;
}
.tdwidth5 {
    width: 5% !important;
}
.tdwidth80 {
    width: 80% !important;
}
.copyemailicons {
    cursor: pointer !important;
    color: green !important;
}
.tdheight20{
    height:20px !important;
}
#tblAcktemplate thead tr th.ascending::after {
    content: '▼';
    display: inline-block;
   
}

#tblAcktemplate thead tr th.descending::after {
    content: '▲';
    display: inline-block;
   
}
.ackediticon {
    background-color: none;
    border: none !important;
    cursor: pointer !important;
}
.tdwidth30 {
    width: 30% !important;
    text-align: left !important;   
    word-wrap: break-word !important;
}
.tdwidth50 {
    width: 50% !important;
    text-align: left !important;
}
.errotextrequiredstyle {
    font-size: 14px;
    color: red;
}
.width200px{
    width:200px !important;
}
.ackInputStyle {
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    border: 1px solid #aeaeae;
    line-height: 22px;
    padding: 6px 10px 5px;
    width: 200px;
    font-size: 13px;
}
body .p-multiselect .p-multiselect-label {
    padding: 0.429em;
    padding-right: 2em;
    font-weight: 300;
    color: #333333;
}
.trendcolumns .p-float-label {
    display: block;
    position: relative;
    font-size: 12px !important;
}
//====================starting of styles for Saw machine (150% scale)==========================//
@media only screen and (max-width: 1280px) and (max-width: 1536px) {

    .iconfont {
        padding: 0.1rem 0.3rem !important;
        opacity: 0.7;
        color: #0080D5;
        background-color: none;
        border: solid;
        height: 28px;
        width: 29px !important;
        border-radius: 8px !important;
        background-color: white;
        cursor: pointer !important;
    }

    .btnwidth100 {
        width: 79px !important;
        font-size: 14px !important;
    }

    .fontcolor {
        color: dimgrey !important;
        font-weight: bold !important;
        font-size: 11px !important;
        
    }
}

.userGuideFontContent {
    font-family: Calibri;
    font-size: large    
}
.userGuideImage {
    width: 90%;
    height:80%

}

.dropdown-ack .p-dropdown-items-wrapper {
    min-width: 30rem;
}

.multiselect-filter .p-multiselect-items {
    min-width: 15rem;
}

.ackFillterModalContent {   
    min-height: 500px !important;
    max-height: 700px !important;
}

/*.IDAck-view {
    margin: auto;
    width: 98%;
}*/

/*.IDAck-wrapper {
    position: relative;
    overflow: auto;
    border: 1px solid black;
    white-space: nowrap;
}*/

.IDAck-sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: #1c648c !important;    
}

.thackFixedcolstyle-first-col {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    text-align: center !important;
    border: 1px solid grey;
    left: 0px;
    z-index: 2;

    
}

.thackFixedcolstyle-second-col {
    width: 175px;
    min-width: 175px;
    max-width: 175px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
    left: 50px;
    z-index: 2
}

.thackFixedcolstyle-third-col {
    width: 275px;
    min-width: 275px;
    max-width: 375px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
    left: 225px;
    z-index: 2
}
.thackFixedcolstyle-forth-col {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}
.thackRingFixedcolstyle {
    min-width: 100px;
    max-width: 100px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.thackPMDEVOwnerFixedcolstyle {
    min-width: 150px;
    max-width: 150px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: break-word !important;
}
.thackOtherFixedcolstyle {
    min-width: 150px;
    max-width: 150px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.tdackFixedcolstyle-first-col {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    text-align: center !important;
    border: 1px solid grey;
    left: 0px;
    z-index: 1;
    color: white;
    font-weight: 600;
}

.tdackFixedcolstyle-second-col {
    width: 175px;
    min-width: 175px;
    max-width: 175px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
    left: 50px;
    z-index: 1; 
    color: white;
    font-weight: 600;
}

.tdackFixedcolstyle-third-col {
    width: 275px;
    min-width: 275px;
    max-width: 375px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
    left: 225px;
    z-index: 1;
    color: white;   
    font-weight: 600;
}

.tdackFixedcolstyle-forth-col {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.tdackRingFixedcolstyle {
    min-width: 100px;
    max-width: 100px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.thackPMDEVOwnerFixedcolstyle {
    min-width: 150px;
    max-width: 150px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: break-word !important;
}

.tdackOtherFixedcolstyle {
    min-width: 150px;
    max-width: 150px;
    text-align: left !important;
    border: 1px solid grey;
    word-wrap: normal !important;
}

.sticky-scroller-IDAck {
    margin-left: 0px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.IDAck-sticky-wrapper {
    position: relative;
    //overflow-y: hidden;
    //border: .5px solid grey;
    //word-break: break-word;
}

.IDAck-sticky-scroller {
    margin-left: 0px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.IDAckTableRowColor {
    background-color: #e8e8e8;
}

.thAckListwidth5 {
    width: 5% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thAckListwidth4 {
    width: 4% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thAckListwidth8 {
    width: 8% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thAckListwidth10 {
    width: 10% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thAckListwidth11 {
    width: 11% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thAckListwidth12 {
    width: 12% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thAckListwidth20 {
    width: 20% !important;
    text-align: left !important;
    font-size: 14px !important;
}