﻿.noflybtn {
    text-align: right;
}

.filternoflybutton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #c7e3ff !important;
    background-color: #c7e3ff !important;
    outline: none;
    font-size: 14px;
}

.moveplnbtn {
    color: white !important;
    background-color: green;
    border-radius: 14px;
}

.moveplnbtn:hover {
    background: green !important;
}

.moveplnbtn:active {
    background: green !important;
}

.EditorGuideline > div:first-child {
    display: none !important;
}

.EditorGuideline > div:last-child {
    background: azure !important;
}

.EditorGuideline {
    border: none !important;
}

.trix-editorCritical {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 320px !important;
    max-height: 320px !important;
    overflow-y: auto;
    font-size: 14px !important;
}

.FlyZoneData > ol {
    color: #004085 !important;
}

.FlyZoneData > ul {
    color: #004085 !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}

.customRichEdit {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 14px;
}


.guidelinestext {
    color: #007bff;
    text-decoration: none;
}

.guidelinestext:hover {
    cursor: pointer;
    text-decoration: underline;
}

.trix-addeditorCritical {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 150px !important;
    max-height: 150px !important;
    overflow-y: auto;
    font-size: 14px !important;
}

.trix-addeditorCritical-date {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 60px !important;
    max-height: 60px !important;
    font-size: 14px !important;
}

.trix-guideitorCritical {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    min-height: 100px !important;
    max-height: 100px !important;
    overflow-y: auto;
    font-size: 14px !important;
}

.identitycriticalchangeadvisory, .azurehighpriorityeventProg {
    margin: 0 5% 0 5%;
}

.identitycriticalchangeadvisory, .plannedazureevents {
    margin: 0 5% 0 5%;
}

.criticaladaddpopup {
    background-color: #6399cd !important;
    color: #fff !important;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
}
.swtch {
    background: rgb(0,136,0) !important;
}

.canameInput {
    width: 70%;
}

.envlist {
    width: 100%
}

.nopaddingleft {
    padding-left: 0px;
}

.divpad {
    padding: 4px;
}

.criticalHeaders {
    color: #3875b2;
    font-size: 20px;
}
