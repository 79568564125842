﻿.newsletterred {
    background-color: red;
}

.newsletterred a {
    color: ghostwhite !important;
}

.newslettergreen {
    background-color: forestgreen;
}

.newslettergreen a {
    color: ghostwhite !important;
}

.OKRspopheight {
    min-height: 650px !important;
    max-height: 650px !important;
}

.newslettergrey {
    background-color: #EBEBEB;
}

.newslettergrey span {
    color: #0067b9 !important;
}

.newslettergrey a {
    color: #0067b9 !important;
}

.fontstyleview{
font-size: 12px;
font-weight: 500;
}

.chartContaineridsentitynewsletter {
    height: 650px !important;
}

.legendtrstyle {
    background-color: white !important;
    color: black !important;
}

.legendthstyle {
    text-align: center !important;
    border: 2px solid black;
    color: black;
    font-family:Calibri;
    font-size:18px;
}

.legendtdstyle {
    text-align: left;
    border: 2px solid black;
    width: 400px;
    color: white;
    height: 400px;
}

.legendtdweight{
    font-weight: bold;
    width: 200px;
}

.legendtdalign {
    width: 200px;
    text-align: center;
    font-family:Calibri;
    font-size:16px;

}

.exceptionlegendtrstyle {
    background-color: white !important;
    color: black !important;
}

.exceptionlegendtdstyle {
    text-align: center !important;
    border: 2px solid black;
    width: 200px;
    color: white;
}

.exceptionlegendtdweight {
    font-weight: bold;
}


.safeflylegendtrstyle {
    background-color: white !important;
    color: black !important;
}

.safeflylegendtdweight {
    font-weight: bold;
    width: 100px;
    font-family:Calibri;
    font-size:16px;

}

.sdpTitle {
    text-align: center;
    font-family:Calibri;
    font-size:22px;
}

.topOutagesheader{
    background-color: orangered;
    color: white !important;
}

.topOutagescircle {
    display: flex;
    width: 225px;
    height: 225px;
    background-color: #f2f2f2 !important;
    border-radius: 50%;
    border-color: #cce5bb !important;
    border:solid;
}

.topOutagesCircletext {
    margin: auto;
    text-align: center !important;
    color: black !important;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.NewsLetterRTELIdots ol, .NewsLetterRTELIdots ul, .NewsLetterRTELIdots dl {
    margin-top: 0px;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 14px !important;
    color: #212529 !important;
    font-weight: normal !important;
    margin-left: 1rem !important;
}

.NewsLetterRTELIdots ul li {
    list-style-type: circle !important;
}

.topOutageTableOuter {
    height: 100%;
    width: 100%;
    min-height: 200px;
    min-width: 500px;    
    border: none;
}

.topOutageSev12TableOuter {
    height: 100%;
    width: 100%;
    border: none;
}

.topOutageTable {
    height: 100%;
    width: 100%;
    background-color: #f2f2f2 !important;
    border: solid;
    border-width: 1px;
}

.topOutageAddInfoTable {
    height: 100%;
    width: 100%;
   
}

.topOutageTableHeaderRow {
    margin: auto;
    text-align: center !important;
    color: black !important;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-bottom:none !important;
    width: 300px !important;
}

.topOutageTableDataRow {
    margin: auto;
    text-align: left !important;
    color: black !important;
    font-size: 12px;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-top: none !important;
}

.topOutageTableValueFormat > span {
    color: black !important;
    background-color: #f2f2f2 !important;
}

.topOutageTableValueFormat > a {
    background-color: #f2f2f2 !important;
}

.topOutageTableIncidentsFormat > span {
    color: black !important;
    background-color: #ffffff !important;
}

.topOutageTableIncidentsFormat > a {
    background-color: #ffffff !important;
}

.topOutageTableTD {
    min-height: 66px;
    min-width: 235px;
    margin: auto;
    border: solid;
    border-width: 1px;
    border-top: none;
}


.topOutageTableTDSummar {
    min-height: 198px;
    min-width: 705px;
    margin: auto;
    border: solid;
    border-width: 1px;
}

.topOutageTableTD {
    min-height: 66px;
    min-width: 235px;
    margin: auto;
    border: solid;
    border-width: 1px;
    border-top:none !important;
}

.topOutageTableTH {
    min-height: 66px;
    min-width: 235px;
    text-align: center !important;
    margin: auto;
    border: solid;
    border-width: 1px;
    background-color: #f2f2f2 !important;   
    border-bottom: none !important;
}

.topOutageAddInfoTableTH {
    min-height: 66px;
    min-width: 235px;
    text-align: center !important;
    margin: auto;
    border: solid;
    border-width: 1px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black !important;
    background-color: #F0FFFF !important;
}

.topOutageSev12TableTH {
    width:100%;
    height:100%;
    text-align: center !important;
    margin: auto;
    border: solid;
    border-width: 1px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black !important;
}

.topOutageTableSummaryTD {
    margin: auto;
    border:thin !important;
    border-color:lightgray !important;
    background-color: white !important;
}

.topOutageAddInfoTableTD {
    min-height: 66px;
    min-width: 235px;
    margin: auto;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    vertical-align:central !important;
    text-align:center !important;
}
.topOutageAddInfoTableTD2 {
    min-height: 66px;
    min-width: 235px;
    margin: auto;
    border: solid;
    border-width: 1px;
}
.topOutageSev12TableTD {
    width: 100%;
    height: 100%;
    margin: auto;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    background-color: white !important;
    vertical-align: central !important;
    text-align: center !important;
}

.topOutageAddInfoTableTD3 {
    min-height: 66px;
    min-width: 235px;
    margin: auto;
    border: solid;
    border-width: 1px;
}

.topOutagessquare {
    min-width: 270px;
    min-height: 250px;
    max-height: 250px;
    background-color: #f2f2f2 !important;
    overflow-y: scroll;
}

.topOutagestext {
    margin: auto;
    text-align: center !important;
    color: white !important;
}

.topOutagessquaretext {
    padding-left: 20px !important;
    word-wrap: break-word;
    /*max-width: 190px;*/
    font-size: 15px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black !important;
}

.aligncenter{
    text-align: center !important;
    padding-top: 5px !important;
}

.incidentnotes{
    border: 2px solid black;
    min-height: 800px !important;
    max-height: 800px !important;
    background-color: azure !important;
    overflow-y: scroll;
}

.topoutageediticon {
    background: none !important;
    border: none !important;
    color: darkblue !important;
}

.deleteIconTopOutage {
    color: red !important;
}

.IconsstyleTopOutages {
    font-size: 20px !important;
    cursor: pointer;
}

.paddingEditSectionTopOutages{
    padding-top: 5px;
}

.inputTypeTopOutage{
    width: 225px;
    height: 225px;
    border-radius: 50%;
    text-align: center !important;
}

.OKRNewsheader {
    background-color: #003d99 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}
.weeklyNewsHeader {
    background-color: #003d99 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}
.topOutageNewsHeader {
    background-color: #003d99 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}


.PowerBiReport-Outage{
    background-color: #003d99 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}

.PowerBiReport-Comms {
    background-color: #003d99 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}

.ValueClass {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.changeMgmtsNewsHeader {
    background-color: #003d99 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}


.KeyResultsHeader {
    background-color: #3971c3 !important;
    color: white !important;
    font-weight: 400;
    height: 30px;
}

.OKRscircle {
    display: flex;
    width: 225px;
    height: 225px;
    background-color: #f2f2f2 !important;
    border-radius: 50%;
    border-color: #cce5bb !important;
    border: solid;
}

.OKRstext {
    margin: auto;
    text-align: center !important;
    color: black !important;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.weekavailabilitysquare {
    text-align: center;
    border: 1px solid rgba(18, 73, 109, 0.4);
    max-width: 170px !important;
    min-width: 170px !important;
    height: 120px !important;
    border-radius: 1rem;
}

.textalignmentweeklyavailability {
    word-wrap: break-word;
    max-width: 160px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: ghostwhite !important;
}

.sendmailbtn{
   height: 40px;
   width: 100px;
}

.alignmailbtn{
    padding-top: 5px;
    padding-left: 5px;
    float: left;
}
.weeklyAvailabilityflexcontainer {
    display: flex;
    flex-wrap: wrap; // To move the td data to next line if length exceeds
}

.weeklyAvailabilityflexcontainerView {
    display: flex;
    align-items: center;
    justify-content: center;
}

.okrstextalignment {
    word-wrap: break-word;
    max-width: 180px;
}

.newsHeadertext {
    font-size: 18px !important;
    font-weight: 700;
    padding-top: 5px;
    text-align: center;
}

.newsHeader {
    font-size: 18px !important;
    padding-left: 40px;
}

.divtablechangeRequests{
    display: flex;
}

.changerequestsTable {
    width: 100%;
}

.outageTableSection {
    width: 100%;
}

.topOutageFristTableHeader {
    background-color: gray !important;
    color: white !important;
    border: 1px solid black;
    font-size: small;    
}

.topOutageFirstColthstyle {
    text-align: center !important;
    border: 1px solid black;
    color: black;
    width: 25px;
    font-size: small;
}

.topOutageSecondColthstyle {
    text-align: center !important;
    border: 1px solid black;
    color: black;
    width: 50px;
    font-size: small;
}

.topOutageThirdColthstyle {
    text-align: center !important;
    border: 1px solid black;
    color: black;
    width: 80px;
    font-size: small;
}

.topOutageForthtoNinethColthstyle {
    text-align: center !important;
    border: 1px solid black;
    color: black;
    width: 40px;
    font-size: small;
}

.topOutageRestOfColthstyle {
    text-align: center !important;
    border: 1px solid black;
    color: black;
    width: 40px;
    font-size: small;
}

.topOutageDataRows {
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
    font-size: small;
}

.topOutageDataRowsTopTable {
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
    font-size: 16px !important;
    font-family: Calibri;
}

.safeflyfirstrow {
    background-color: black !important;
    color: white !important;
    border: 2px solid black;
}

.safeflyfirstrow > td:first-child {
    border-right: 2px solid black;
}

.safeflysecondrow {
    background-color: white !important;
    color: black !important;
    border: 2px solid black;
}

.safeflysecondrow td{
   border: 2px solid black;
}

.topOutageEditor {
   min-width: 166px !important;
   min-height: 150px !important;
}

.topOutageEditor > div:last-child {
    min-height: 150x;
}

.topOutageIncidentEditor {
    height: 700px !important;
    width: 100% !important;
}

.ObjectiveInput{
    display: flex;
}

.ObjectivesStyle {
    min-width: 90% !important;
    max-width: 90% !important;
}

.InputStyleObjectiveDate {
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    border: 1px solid #aeaeae;
    line-height: 22px;
    padding: 6px 10px 5px;
    width: 220px;
    font-size: 17px;
}

.objectiveskeyResultsDate{
    display : flex;
    justify-content: center;
}

.p-md-4-objective{
    padding: 1rem !important;
}

.weeklyavailabilitytable {
    table-layout: fixed;
    width: 100% !important;
    background-color: lightsteelblue;
    border: 1px solid grey;
}

.weekavailabilitysquarepadding {
    padding-right: 10px;
    padding-bottom: 10px;
}


.trix-addeditorIdentityNews-data {
    margin: 0px !important;
    min-height: 60px !important;
    max-height: 60px !important;
    font-size: 14px !important;
}

.customRichEditIndentityNews {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 11px;
    width: 98% !important;
}
.customRichEditTopIndentityNews {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 11px;
    width: 90% !important;
}
.Embed-container {
    height: 600px;
    width: 900px;
}