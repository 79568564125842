.scrolldata {
    width: auto;
    max-height: 500px;
    overflow-x: auto;
}
.sticky-table {
    border-collapse: collapse;
    text-align: center;
    border-top: 2px solid transparent;
    line-height: 25px !important;
    overflow-x: scroll;
}
.Content > ul{
    color: black;
    font-weight: normal;
}
.sticky-table thead th {
    padding:2px 8px !important;
    text-align: center;
    /*white-space: nowrap;*/
}
.sticky-table tbody td {
    text-align: center;
}
.sticky-wrapper {
    position: relative;
    overflow-y: hidden;
    //border: .5px solid grey;
    //word-break: break-word;
}

.sticky-scroller {
    margin-left: 240px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}
.sticky-scroller_vol {
    margin-left: 310px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}
.sticky-scroller-20 {
    margin-left: 430px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}
.sticky-scroller-20_vol {
    margin-left: 470px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-scroller-50 {
    margin-left: 630px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}
.sticky-scroller-50_vol {
    margin-left: 670px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-table .sticky-fixed-col-th {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 160px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col-th-20 {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 349px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col-th-50 {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 551px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}

.sticky-table .sticky-fixed-col2-th {
    left: 159px;
    position: absolute;
    top: auto;
    width: 82px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}
.sticky-table .sticky-fixed-col2-th_vol {
    left: 149px;
    position: absolute;
    top: auto;
    width: 162px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}
.sticky-table .sticky-fixed-col2-th-20 {
    left: 350px;
    position: absolute;
    top: auto;
    width: 82px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}
.sticky-table .sticky-fixed-col2-th-20_vol {
    left: 390px;
    position: absolute;
    top: auto;
    width: 142px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
}
.sticky-table .sticky-fixed-col2-th-50 {
    left: 550px;
    position: absolute;
    top: auto;
    width: 81px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
    //border-right:solid !important;
}
.sticky-table .sticky-fixed-col2-th-50_vol {
    left: 550px;
    position: absolute;
    top: auto;
    width: 141px;
    //background-color: #dee9f5;
    background-color: #F0FFFF;
    //border-right:solid !important;
}


.sticky-table .sticky-fixed-col {
    left: 0;
    position: absolute;
    text-align: left !important;
    word-break:break-word;
    top: auto;
    margin: auto;
    width: 160px;
    height: 100px;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    //max-width: 120px;
}

.sticky-table .sticky-fixed-col-20 {
    left: 0;
    position: absolute;
    text-align: left !important;
    word-break: break-word;
    top: auto;
    margin: auto;
    width: 350px;
    height: 100px;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    //max-width: 120px;
}

.sticky-table .sticky-fixed-col-50 {
    left: 0;
    position: absolute;
    text-align: left !important;
    word-break: break-word;
    top: auto;
    margin: auto;
    width: 551px;
    height: 100px;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    //max-width: 120px;
}


.sticky-table .sticky-fixed-col2 {
    left: 159px;
    position: absolute;
    width: 82px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
/*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}
.sticky-table .sticky-fixed-col2_vol {
    left: 159px;
    position: absolute;
    width: 162px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}
.sticky-table .sticky-fixed-col2-20 {
    left: 349px;
    position: absolute;
    width: 82px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}
.sticky-table .sticky-fixed-col2-20_vol {
    left: 349px;
    position: absolute;
    width: 142px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}
.sticky-table .sticky-fixed-col2-50 {
    left: 550px;
    position: absolute;
    width: 81px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}
.sticky-table .sticky-fixed-col2-50_vol {
    left: 550px;
    position: absolute;
    width: 141px;
    height: auto;
    //min-height: 50%;
    //background-color: #eff3d8;
    background-color: #F0FFFF;
    /*    word-break: break-word;*/
    /*overflow-y:hidden;*/
    //line-height: 9.3pt;
    text-align: right !important;
    top: auto;
    margin: auto;
}

th.ascending::after {
    content: '▼';
    display: inline-block;
    margin-left: 0.8em;
}
th.descending::after {
    content: '▲';
    display: inline-block;
    margin-left: 0.8em;
  }
.sticky-scroller::-webkit-scrollbar-track {
    background: transparent;
  }

/*.sticky-fixed-col2::-webkit-scrollbar-thumb {
    color:transparent;
    background-color:transparent;
}

.sticky-fixed-col2::-webkit-scrollbar-track {
    color: transparent;
    background-color: transparent;
}
*/

.sticky-table .sticky-reachability-fixed-col-th {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 179px;
    background-color: #dee9f5;
}

.sticky-table .sticky-reachability-fixed-col {
    left: 0;
    position: absolute;
    text-align: left !important;
    width: 180px;
    // background-color: #eff3d8;
    background-color: #F0FFFF;
    display: flex;
    flex-direction: column;
    top: auto;
    margin: auto;
    height: 100px;
    //min-height: 50%;
    /*    border: 2px solid red !important;*/
    /*    height:50%;*/
    /*    height: -webkit-fill-available;*/
}


.sticky-table .sticky-reachability-fixed-col-th-20 {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 349px;
    background-color: #dee9f5;
}

.sticky-table .sticky-reachability-fixed-col-20 {
    left: 0;
    position: absolute;
    text-align: left !important;
    width: 350px;
    // background-color: #eff3d8;
    background-color: #F0FFFF;
    display: flex;
    flex-direction: column;
    top: auto;
    margin: auto;
    height: 100px;
    //min-height: 50%;
    /*    border: 2px solid red !important;*/
    /*    height:50%;*/
    /*    height: -webkit-fill-available;*/
}

.sticky-table .sticky-reachability-fixed-col-th-50 {
    left: 0;
    position: absolute;
    text-align: center !important;
    top: auto;
    width: 650px;
    background-color: #dee9f5;
}

.sticky-table .sticky-reachability-fixed-col-50 {
    left: 0;
    position: absolute;
    text-align: left !important;
    width: 650px;
    // background-color: #eff3d8;
    background-color: #F0FFFF;
    display: flex;
    flex-direction: column;
    top: auto;
    margin: auto;
    height: 100px;
    //min-height: 50%;
    /*    border: 2px solid red !important;*/
    /*    height:50%;*/
    /*    height: -webkit-fill-available;*/
}

.sticky-reachability-scroller {
    margin-left: 179px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border:none !important;
}

.sticky-reachability-scroller::-webkit-scrollbar-track {
    background: transparent;
  }

.sticky-reachability-scroller-20 {
    margin-left: 349px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-reachability-scroller-20::-webkit-scrollbar-track {
    background: transparent;
}

.sticky-reachability-scroller-50 {
    margin-left: 650px;
    overflow-x: scroll;
    overflow: auto;
    padding-bottom: 1px;
    border: none !important;
}

.sticky-reachability-scroller-50::-webkit-scrollbar-track {
    background: transparent;
}

.filler {
    border: 2px solid black !important;
    background-color: #eff3d8 !important;
    overflow:hidden
}
.sticky-table tbody {
    line-height: 29px;
}
.picky__dropdown .option, .picky__dropdown li {
    list-style: none;
    background-color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.5em;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    text-align: left;
}
.bulkuodateicon {
    border-radius: 4px;
    color: white;
    background-color: #2e6da4;
    vertical-align: middle;
    border: 2px;
    border-color: #2e6da4;
    padding: 0.25rem 0.3rem;
    font-size: medium;
    text-decoration: none;
    font-weight: normal;
    animation: fade 1s;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-external-link-square:before {
    content: "\f14c";
}
.blackicon {
    color: black !important;
    font-size: 10px !important;
}
.lgOMJT {
    padding-left: 1.5rem;
    font-size: 14px !important;
}
   
//====================starting of styles for Saw machine (150% scale)==========================//
@media only screen and (max-width: 1280px) and (max-width: 1536px) {
    .icon-format {
        padding: 0.1rem 0.3rem !important;
        opacity: 0.7;
        color: #0080D5;
        cursor: pointer;
        background-color: none;
        border: solid;
        height: 20px !important;
        width: 22px !important;
        border-radius: 8px !important;
        background-color: white;
    }
    .icon-format-yellow-invert {
        padding: 0.1rem 0.3rem !important;
        opacity: 1;
        color: #ffc000;
        cursor: pointer;
        border: solid;
        border-color: #ffc000;
        height: 20px;
        width: 22px !important;
        border-radius: 8px !important;
        background-color: white;
    }
    .icon-format-red-invert {
        padding: 0.1rem 0.3rem !important;
        opacity: 1;
        color: #ffc7ce;
        cursor: pointer;
        border: solid;
        border-color: #ffc7ce;
        height: 20px;
        width: 22px !important;
        border-radius: 8px !important;
        background-color: white;
    }
    .lgOMJT {
        padding-left: 1.5rem;
        font-size: 10px !important;
    }
   
    .picky {
        width: 100%;
        position: relative;
        font-size: 12px;
    }

    .nav-tabs {
        border-bottom: 1px solid #dee2e6;
        font-size: 12px !important;
    }
    .bulkuodateicon {
        border-radius: 4px;
        color: white !important;
        background-color: #2e6da4;
        vertical-align: middle;
        border: 2px;
        border-color: #2e6da4;
        padding: 0.25rem 0.3rem;
        font-size: 12px !important;
        text-decoration: none;
        font-weight: normal;
        animation: fade 1s;
    }
    .InputStyle {
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        border: 1px solid #aeaeae;
        line-height: 22px;
        padding: 6px 10px 5px;
        width: 120px;
        font-size: 13px;
    }
    .fontmedium{
        font-size:medium !important;
    }
    .changetext .lgOMJT{
        font-size:14px !important;
    }
    .navAnchor {
        padding: 0.25rem 0.3rem !important;
        font-size: 14px !important;
        color: black !important;
        text-decoration: none !important;
        font-weight: normal !important;
        vertical-align: top;
    }
    .sawfont{
        font-size:10px !important;
    }
}

.css-df17o1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color: #FFF;
    background: rgba(0,0,0,0.7);
    z-index: 1090;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
    opacity: 1;
}

.RTELIdots ol, .RTELIdots ul, .RTELIdots dl {
    margin-top: 0px;
    margin-bottom: 1rem;
    font-weight: bold;
    color: #212529 !important;
    font-weight: normal !important;
    margin-left: 1rem !important;
}
.RTELIdots ul li {
    list-style-type: circle !important;
}

/*.rowdisplay div {
    display: inline-block;
}

.rowdisplay div:hover {
    text-decoration: underline;
}
*/
.DailyTooltipLS {
    position: relative;
    display: inline-block;

}

.DailyTooltipLS .DailyTooltiptextLS {
    visibility: hidden;
    width: 600px;
    height: 500px;
    //background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    position:absolute ;
    z-index: 1;
    bottom: 125%;
    left: 70%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:small;
    text-align:left;
    color:black;
   
}

.DailyTooltipLS .DailyTooltiptextLS::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.DailyTooltipLS:hover .DailyTooltiptextLS {
    visibility: visible;
    opacity: 1;
/*    text-decoration: underline;*/
}

.tooltipTD1 {
    background-color: lightcyan;
    font-size: 11px;
    color: black;
    width: 25%;
    text-align: left !important;
}

.tooltipTD2 {
    background-color: azure;
    font-size: 11px;
    color: black;
    width: 75%;
    text-align: left !important;
}

.tblCommentsDaily {
    border-radius: 5px !important;
    word-wrap: break-word;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    table-layout: fixed;
}

.tblCommentsDaily thead tr {
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}

.tblCommentsDaily thead tr th {
    text-align: center !important;
    font-size: 14px !important;
    vertical-align: middle !important;
    color: #000000 !important;
}

.tblCommentsDaily tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.tblCommentsDaily td {
    border: 1px solid #00000029 !important;
}
.commentsADO {
    font-style: normal;
    font-size:12 px;
}

.commentsICM {
    font-style: italic;
    font-size:10px;
}