﻿.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.divhelpicon {
    float:right;
    padding-top:4px;

}
.styhelpicon{
    font-size:20px !important;
    color:black !important;
}
.anchtextdecoration {
    text-decoration: none;
}
.lblnames{
    float:right !important;
}
.viewConfigRowstyle{
    padding-top:15px !important;
}
.viewconfigdivchecks{
     padding-top:14px !important;
 }
.viewconfiginputstyle{
    max-width:200px !important;
}

.viewconfiginputnewstyle{
    max-width: 98%;
}

.labelpaddingTSG{
   padding-right: 5px !important;
   float: left;
   padding-top:5px !important;
}

.viewConfigRowbtmstyle {
    padding-bottom: 15px !important;
}
.viewcard {
    border-radius: 1px;
    border:1px solid grey;
    width: 100%;
     padding: 0;
}
.viewcardbody {
    border-radius: 1px;
    width: 100%;
    padding: 0.5rem;
    background: #DEE9F5;
    color: #000000;
    opacity:0.8;
}
.txtareaheight{
    height:150px !important;
}
.divconfigupdatedetails {
    font-size: small;
    float: right;
}
