﻿.missingdatatable {
    border: 1px solid;
    padding: .429em .857em;
    /*overflow-y: scroll;*/
    display: block;
    border-color: #fbfafa;
}

.inputRequired {
    font-weight: bold;
    color: red;
}

.missingdatatablehead {
    background-color: #3875b2 !important
}

.missingdatatableth {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.missingdatatabletd {
    border: 1px solid #dee2e6;
}

.mctable {
    float: left !important;
    width: 100% !important;
}

.mchead tr th {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.mcmgroup {
    width: 10% !important;
    float: left;
}

.mcname {
    width: 20% !important;
    float: left;
}

.mcisedit {
    width: 10% !important;
    float: left;
}

.mcisgraph {
    width: 10% !important;
    float: left;
}

.mctraget {
    width: 10% !important;
    float: left;
}

.mcminrange {
    width: 10% !important;
    float: left;
}

.mcmaxrange {
    width: 10% !important;
    float: left;
}

.mcinterval {
    width: 10% !important;
    float: left;
}

.mcvalidaexp {
    width: 10% !important;
    float: left;
}

.crservicedrop {
    width: 20% !important;
    float: left;
    padding-right: 10px;
}

.crmetricdrop {
    width: 12% !important;
    float: left;
    padding-right: 10px;
}

.crloadmorebtn {
    width: 10% !important;
    float: left;
}

.crdatatable {
    width: 100%;
    margin-top: 6%;
}

#crServiceNames {
    width: 100%;
}

#metricGroups {
    width: 100%;
}

.crmetricdetails {
}

#metrics {
    width: 27%;
}

#targetDetails {
    width: 18%;
}

.mctd {
    border: 1px solid #dee2e6;
}

.cmmetricname {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 25%;
}

.cmdisplayname {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 30%;
}

.cmmetricgroup {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 15%;
}

.cmenvironment {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 15%;
}

.cmlastth {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

#cmmetricGroups {
    width: 100%;
}

#cmenvironment {
    width: 100%;
}

#cmNewMetricGroups {
    width: 75%;
}

#cmNewEnvironment {
    width: 75%;
}

#newMetricName {
    width: 75%;
}

#newMetricDisplayName {
    width: 75%;
}

.cmupdate {
    margin-right: 10px !important;
}

.cmdelete {
    margin-right: 10px !important;
    color: #212529 !important;
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.cmtable {
    width: 100%;
    border: 1px solid #dee2e6;
    margin-bottom: 6%;
    margin-top: 2%;
}

.cmthead {
}

.cmtd {
    padding: 10px;
    border: 1px solid #dee2e6;
}

.cmtdbtn {
    padding: 10px;
    border: 1px solid #dee2e6;
    width: 11%;
}

.cmscoretable {
    width: 100%;
    border: 1px solid #dee2e6;
}

.cmscorehead {
}

.cmscoremetric {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.cmscorered {
    background-color: indianred;
    color: #fbfafa;
    text-align: center !important;
}

.cmscoreyellow {
    background-color: #a9770e;
    color: #fbfafa;
    text-align: center !important;
}

.cmscoregreen {
    background-color: #0b6709;
    color: #fbfafa;
    text-align: center !important;
}

.historydatatable {
    width: 74%;
    margin: 25px;
}

.historydatatr {
}

.historydataservice {
    width: 25%;
}

.historydatagroups {
    width: 20%;
}

.historydatafrom {
    width: 16%;
}

.historydatato {
}

#historyServiceNames {
    width: 100%;
}

#historymetricGroups {
    width: 100%;
}

.historydtable {
    width: 100%;
}

.historythead {
}

.hsitorymetric {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 30%;
}

.hsitoryenv {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.historyweek {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.historymodified {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.historyprv {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.historycurrent {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.historymodifby {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.historytd {
    text-align: center !important;
    border: 1px solid #dee2e6;
}

.historytdmetric {
    border: 1px solid #dee2e6;
}

.hmServiceName {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 10%;
}

.hmName {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 10%;
}

.hmJustify {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 15%;
}

.hmOriginalTarget {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 6%;
}

.hmNewTarget {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 5%;
}

.hmReviewComts {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 30%;
}

.hmbtns {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
    width: 20%;
}

#heatmapServiceNames {
    width: 100%
}

.heatmapsrv {
    width: 20%;
}

.heatmapfrm {
    width: 12%;
}

.heatmapto {
}

.heatviewpoptd {
    border: 1px solid #dee2e6;
}

.heatmaptr th {
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.heatmaptable {
    width: 100%;
    background-color: aliceblue;
}

.heatmapthead {
    color: white;
    background-color: #27607c;
    font-family: Segoe UI;
}

.heatmapparentdiv {
    height: 100% !important;
    overflow-y: scroll;
    width: 100% !important;
    overflow-x: scroll;
}

.viewpoptable {
    width: 100%;
}

.viewpopthead {
    width: 100%;
    background-color: #3875b2 !important;
    color: #fbfafa;
    font-size: 14px;
    text-align: center !important;
}

.hyptextdecor {
    text-decoration: underline !important;
    color: blue !important;
}

.heatmaptab {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.heatmaphistd {
    border: 1px solid #dee2e6;
}

#serviceNames {
    width: 18%;
}

.slidercls {
    width: 54%;
    float: left;
    margin-left: 2%;
    margin-top: 6px;
}

.sliderName {
    float: left;
}

.sliderval {
    float: left;
    padding-left: 1%;
}

.slider {
    height: 13px;
}

.sliderprdiv {
    margin-top: 6%;
    margin-left: 4%;
}

.adminrestriction {
    text-align: center;
    margin-top: 12%;
    color: red;
}

.mctablediv {
    margin-top: 3%;
    height: 100% !important;
    overflow-y: scroll;
    width: 100% !important;
    overflow-x: scroll;
    border-top: 1px solid black;
}

.approveBtn {
    padding: 2px !important;
    border-radius: 8px !important;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 150px;
    max-width: 150px;
    color: #fff !important;
    padding-left: 4% !important;
    margin-right: 4% !important;
}

.rejectBtn {
    margin-right: 5% !important;
    padding-left: 4% !important;
    padding-top: 4px !important;
    border-radius: 8px !important;
    border-color: #dc3545 !important;
    background-color: #dc3545 !important;
    outline: none;
    min-width: 150px;
    max-width: 150px;
    color: #fff !important;
}

.updateMetricConfig {
    padding: 2px !important;
    border-radius: 8px !important;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 150px;
    max-width: 150px;
    color: #fff !important;
    padding-left: 4% !important;
    margin-right: 9% !important;
}

.cancelMetricConfig {
    margin-right: 16% !important;
    padding-left: 4% !important;
    padding-top: 4px !important;
    border-radius: 8px !important;
    border-color: #dc3545 !important;
    background-color: #dc3545 !important;
    outline: none;
    min-width: 150px;
    max-width: 150px;
    color: #fff !important;
}

.metricconfigpoptd {
    border: 1px solid #dee2e6;
}
