﻿.row-bottom-margin {
    margin-bottom: 5px;
    margin-top:5px;
}


.checklisttable {
    border: 1px solid gray;
    border-radius: 8px;
    border-collapse: inherit !important;
}

.ChecklistLockbutton {
    padding: 7px !important;
    border-radius: 4px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    color: white;
    text-align: right;
}

.ocetextunderline:hover {
    text-decoration: underline;
}

.checklistwarntextstyle {
    font-size: 15px;
    color: red;
}

.actionItemtableoceview tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.actionItemstdstyletable {
    text-align: center !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}

.actionItemtableoceview tbody tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}

.actionItemtableoceview td {
    border: 1px solid #00000029 !important;
}

.actionItemtableocereadview tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.actionItemtableocereadview tbody {
    background-color: lightcyan;
}

.actionItemtableocereadview td {
    border: 1px solid #00000029 !important;
}

.thstyleOnCallReadOnlyHead {
    background-color: #c7e3ff;
    text-align: center !important;
    border: 1px solid rgba(18,73,109,.4);
}

.serevicedropdownchecklist > div:last-child {
    width: 260px !important;
}

.sereviceNamedropdownchecklist > div:last-child {
    width: 320px !important;
}

.OncallChecklistbutton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 100px;
    max-width: 100px;
    color: white !important;
}

.OncallChecklistOCEIncidentbutton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 80px;
    max-width: 80px;
    color: white !important;
}
.OCEViewbutton {
    padding: 2px !important;
    border-radius: 8px;
    border-color: #2e6da4 !important;
    background-color: #337ab7 !important;
    outline: none;
    min-width: 75px;
    max-width: 75px;
    color: white !important;
}

.lblheader {
    font-weight: bold;
    text-align: center;
}


th.thSort {
    cursor: pointer;
}

th.thSort > div > div {
    color: lightslategray;
    text-decoration: none;
    display: inline;
}

th.thSort > div > div:hover {
    color: darkgrey;
}

th.thSort > div > div.iconSort.active {
    color: #ffffff;
}

.thstyleOnCall {
    background-color: #c7e3ff;
    text-align: center !important;
}

.thstyleOnCallReadOnly {
    background-color: #c7e3ff;
    text-align: center !important;
    border: 1px solid rgba(18,73,109,.4);
}

.actionitemstablebordered td, .actionitemstablebordered th {
    border: 1px solid #dee2e6;
}

.textalign_wordwrap {
    text-align: left;
    word-wrap: break-word !important;
    min-width: 180px;
    max-width: 180px;
}

.textalignleft {
    word-wrap: break-word !important;
}


.table-hover td {
    background-color: rgba(234, 216, 128, 0.31);
}

.table-hover .td-value {
    background-color: rgba(234, 216, 128, 0.31);
}

.table-bordered {
    border: 1px solid lightgrey;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid lightgrey;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.OCEViewTable {
    border: 1px solid gray;
    border-radius: 8px;
    border-collapse: inherit !important;
}

.checklistheaderstyleReadOnly {
    text-align: left;
    padding-left: 2vw;
    width: 27%;
    border-right: 1px solid grey;
    vertical-align: middle;
}

.checklistheaderstyle {
    text-align: left;
    padding-left: 5vw;
    width: 20%;
    border-right: 1px solid grey;
    vertical-align: middle;
}

.checklistheaderstyleButtons {
    text-align: right;
    padding-left: 8vw;
    width: 20%;  
    vertical-align: middle;
}

.calendarstyle > input{
    width: 110px;
}

.tdstyleOCE{
    text-align: center !important;
}

.OCEListLabelstyle{
    color: #148 !important;
}

.OCEViewIncidenttable {
    border: 1px solid gray;
    border-radius: 8px;
    border-collapse: inherit !important;
}

.actionitemstablebordered td, .actionitemstablebordered th {
    border: 1px solid #dee2e6;
}

#OCEIncidentsPopup{
    min-height: 300px !important;
}

.p-multiselect-item.p-highlight {
    color: black !important;
    background-color: white !important;
}

.btninfoAddActionItem {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btninfoAddActionItem:hover {
    color: #fff !important;
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.requiredactnItem:after {
    content: "*Required Field";
    color: red;
}

.btninfoAddActionItem:disabled {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

#incidentFilterSeverity {
    width: 100% !important;
}

.thstyleOCEActionItem {
    background-color: #337ab7;
    color: white;
    vertical-align: middle;
    text-align: center !important;
    height: 40px;
}

.oce-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.005em;
}

.OCEpanel-heading {
    color: black !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.005em;
}

.OCEpanel-headingIFrame {
    color: white !important;   
}

.OCEpanel-default {
    border-color: #ddd;
}

.OCEpanel-default > .OCEpanel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.OCEpanel-default > .OCEpanel-heading + .OCEpanel-collapse > .OCEpanel-body {
    border-top-color: #ddd;
}

.OCEpanel-default > .OCEpanel-heading .badge {
    color: #f5f5f5;
    background-color: #333333;
}

.OCEpanel-default > .OCEpanel-footer + .OCEpanel-collapse > .OCEpanel-body {
    border-bottom-color: #ddd;
}

.OCEpanel-primary {
    border-color: #337ab7;
}

.OCEpanel-primary > .OCEpanel-heading {
/*        color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;*/
    color: black !important;
    background-color: white !important;
    border-color: gray !important;
}

.OCEpanel-primary > .OCEpanel-heading + .OCEpanel-collapse > .OCEpanel-body {
    //border-top-color: #337ab7;
    border-top-color: gray !important;
}

.OCEpanel-primary > .OCEpanel-heading .badge {
    color: #337ab7;
    background-color: #fff;
}

.OCEpanel-primary > .OCEpanel-footer + .OCEpanel-collapse > .OCEpanel-body {
    border-bottom-color: #337ab7;
}


.tblListDisplay {
    border-radius: 5px !important;
    width: 100%;
    background-color: #f0ffff !important;
}

.tblListDisplay thead tr th {
    color: #000000 !important;
    background-color: #c7e3ff !important;
    text-align: center !important;
    font-size: 14px !important;
}

.tblListDisplay tbody tr td {
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 3px;
    padding-bottom: 2px;
    line-height: 21px;
    vertical-align: middle;
}

.tblListDisplay tbody tr > td > div p > span {
    background-color: transparent !important;
}

.tblListDisplay tbody tr > td > div p > a {
    background-color: transparent !important;
}

.tblListDisplay tbody tr > td > div > ul li > span {
    background-color: transparent !important;
}


.tblListDisplay tbody tr:nth-child(odd) {
    background-color: rgba(0,0,0,.05);
}

.tblListDisplay td {
    border: 1px solid #00000029 !important;
}


.tdListstyle {
    text-align: left;
}

.thListwidth5 {
    width: 5% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thListEditwidth4 {
    width: 4% !important;
    text-align: center !important;
    font-size: 14px !important;
}

.thListwidth8 {
    width: 8% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thListwidthCenter8 {
    width: 8% !important;
    text-align: center !important;
    font-size: 14px !important;
}
.thListwidth10 {
    width: 10% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thListwidth11 {
    width: 11% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thListwidth12 {
    width: 12% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thListwidth20 {
    width: 20% !important;
    text-align: left !important;
    font-size: 14px !important;
}

.thListwidth12 {
    width: 12% !important;
    text-align: left !important;
    font-size: 14px !important;
}



.ChecklistQuestionaireTableOuter .p-orderlist .p-orderlist-list {
    width: 98%;
    background-color: #ffffff00;
    border-left: 1px solid #c8c8c8 !important;
    border-top: 1px solid #c8c8c8 !important;
    border-bottom: 0px !important;
    border-right: 0px !important;
    overflow-x: hidden;
    margin-left: 1%;
}

.ChecklistQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight a {
    color: white;
}

.ChecklistQuestionaireTableOuter .p-orderlist .p-orderlist-controls {
    width: 2%;
}

.NoArrowOrderList .p-orderlist .p-orderlist-controls {
    display: none;
}

.ChecklistQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0px !important;
    font-weight: 500;
}

.ChecklistQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item tr > td {
    padding: 0px 0px 0px 10px !important;
    border-right: 1px solid #c8c8c8 !important;
    border-bottom: 1px solid #c8c8c8 !important;
    word-break: break-word;
}

.ChecklistQuestionaireTableOuter .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    background-color: #007ad9a3;
}

.ChecklistQuestionaireTableOuter .p-orderlist .p-button {
    background-color: #2e6da4 !important;
    border: #2e6da4 !important;
}
.checlistEditicon {
    background: none !important;
    border: none !important;
    cursor: pointer !important;
    color: blue !important;
}

.divChecklistFixedHeader {
    z-index: 100 !important;
    width: 96vw;
    position: fixed;
    background-color: lightcyan !important;
}
