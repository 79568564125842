﻿.idnacardheader {
    background-color: #243a5e !important;
    height: 100px !important;
    text-align: center;
    color: #ffffff !important;
}

.headertitle {
    color: #ffffff !important;
    font-weight: bold;
    font-size: xx-large;
}

.usersicon {
    color: #253a60;
    cursor: pointer;
    height: 50px;
    margin-right: 44px;
    width: 60px !important;
    margin-top: 20px;
}

.idnacardsubheader {
    background-color: #0078d4 !important;
    text-align: center;
    color: #ffffff !important;
    height: 50px;
}

.idnacardnavdetails {
    text-align: left;
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
}

.tileTopRow {
    text-align: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
}

.tileTopCol {
    border: 2px solid;
    height: 450px !important;
    background: #e7e6e6;
    margin-right: 30px;
    padding: 20px;
    width: 30%;
    border-color: darkgray;
    max-width: 15%;
    white-space: initial;
    overflow-wrap: break-word;
    border: 2px solid;
}

.tileBox {
}

.tileValue {
    font-weight: bold;
    font-size: 40px;
}

.tileHeader {
    font-size: 16px !important;    
    font-weight: 700 !important;
    font-family: Segoe UI !important;
}
.tileInfo {
}

.tileLink {
}

.topOutageNewsHeader {
    background-color: #0070c0 !important;
    color: white !important;
    font-weight: 500;
    height: 35px;
}

.redirectLinks {
    color: blue;  
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.newsContentHeader {
    font-size: x-large;
    font-weight: 600;
    font-family: sans-serif;
    color: #0078d4
}

.emptydiv{
    width: 8%;
    max-width: 8%;
}

.noDays {
    text-align: right;
    margin-right: 9%;
    font-size: 11.5pt;
    font-family: Segoe UI;
    color: blue;
    font-weight: bold;
}

.lastweekTable {
    font-family: Segoe UI;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
}

.lastweekTablehead{

}

.lastweekTablehead tr {
    background-color: #dee9f5 !important;
    color: #707070;
    font-size: 14px;
}

.lastweekTablehead tr th {
    position: sticky;
    top: 0px;
    text-align: center !important;
    font-family: Segoe UI;
    color: black !important;
    font-size: 10.5pt;
    background-color: white !important;
    border: 1px solid black;
    padding: 5px;
}

.lastweekTablebody tr td {
    padding: 5px;
    text-align: center;
    width: 5%;
    border: 1px solid #797878;
    background-color: white;
}

.refreshBtn {
    height: 30px;
    width: 200px;
}